import React, { Fragment, useEffect, useState } from "react";
import Table from "../../../components/tables/table";
import {
  payment_columns,
  therapist_payment_columns,
} from "../../../components/tables/tableheader";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, useFormik } from "formik";
import { markPaidSchema } from "../../../schemas";
import moment from "moment";
import axios from "axios";
import { getHeaders } from "../../../redux/auth/actions";
import { toast } from "react-hot-toast";
import Modal from "../../../components/Modal";
import PhoneInput from "react-phone-input-2";
import * as XLSX from "xlsx";

const api = process.env.REACT_APP_API_URL;

const PaymentLogs = () => {
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const [query, setQuery] = useState(200);
  const [paymentLog, setSetPaymentLog] = useState({
    loading: false,
    data: [],
  });
  const [sconfirmationModal, setsConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [paid, setPaid] = useState(false);
  const [date, setDate] = useState("");
  const dispatch = useDispatch();
  const [markPaidId, setMarkPaidId] = useState(null);

  // Updated filter states to match backend data
  const [filterInstallment, setFilterInstallment] = useState("");
  const [filterPaymentSuccess, setFilterPaymentSuccess] = useState("");

  useEffect(() => {
    document.title = "New Horizon | Payments";
    if (paid) {
      getPaymentLogs();
    }
  }, [paid]);

  const markPaid = (id) => {
    setMarkPaidId(id);
  };

  const sendPaymentLinkConfirmation = (id) => {
    setsConfirmationModal({ id, status: true });
  };

  const onSend = (id) => {
    setConfirmationModal({ id: null, status: false });
  };

  const sendInvoiceLink = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const sendInvoice = (id) => {
    setConfirmationModal({ id: null, status: false });
  };

  if (paid) {
    setPaid(false);
  }

  useEffect(() => {
    if (filterInstallment || filterPaymentSuccess !== "") {
      getPaymentLogs("");
    } else {
      getPaymentLogs(200);
    }
  }, [filterInstallment, filterPaymentSuccess]);

  async function getPaymentLogs(limit) {
    const headers = await dispatch(getHeaders());
    try {
      setSetPaymentLog((prev) => ({ ...prev, loading: true }));
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/api/therapist/appointment/payment/all${
          limit ? `?limit=${limit}` : ""
        }`,
        { headers }
      );
      const { data } = response;
      if (data) {
        let filteredData = data.data.map((p) => ({
          ...p,
          status: p.payment_countdown_date
            ? moment(p.payment_countdown_date).diff(
                moment().toISOString(),
                "days"
              ) <= 3
              ? "Due Soon"
              : "On Time"
            : "Pending",
        }));

        // Apply filters to the data
        if (filterInstallment) {
          filteredData = filteredData.filter(
            (p) => p.installment === filterInstallment
          );
        }

        if (filterPaymentSuccess !== "") {
          filteredData = filteredData.filter(
            (p) => p.is_success === (filterPaymentSuccess === "true")
          );
        }

        setSetPaymentLog((prev) => ({
          ...prev,
          data: filteredData,
        }));
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setSetPaymentLog((prev) => ({ ...prev, loading: false }));
    }
  }

  // filter function
  const filterData = () => {
    let filteredData = paymentLog.data;

    // Filter by Installment
    if (filterInstallment) {
      filteredData = filteredData.filter(
        (p) => p.installment === filterInstallment
      );
    }

    // Filter by Payment Success
    if (filterPaymentSuccess !== "") {
      filteredData = filteredData.filter(
        (p) => p.is_success === (filterPaymentSuccess === "true")
      );
    }
    return filteredData;
  };

  // Download Excel function
  const downloadExcel = () => {
    const dataToExport = filterData().map((item) => ({
      "PATIENT NAME": `${item.enrollment.appointment.patient.firstName} ${item.enrollment.appointment.patient.lastName}`,
      "PAYMENT ID": item.payment_id ? item.payment_id : "N/A",
      STATUS: item.is_success ? "Successfull" : "unsuccessfull",
      "INSTALLMENT TYPE": item.installment,
      AMOUNT: item.total_amount,
      "TOTAL PAYMENT": item.enrollment.total_amount,
      "PAYMENT COUNTDOWN":
        item.payment_countdown_date && item.installment !== "FIRST"
          ? moment(item.payment_countdown_date).diff(
              moment().toISOString(),
              "days"
            )
          : "-",
      " STATUS": item.installment === "FIRST" ? "-" : item.status,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payment Logs");

    XLSX.writeFile(
      workbook,
      `payment_logs_${moment().format("YYYY-MM-DD")}.xlsx`
    );
  };

  const ssinitialModalState = {
    type: "",
    title: "",
    state: false,
    index: "",
    edit_id: "",
  };
  const [smsModal, setSmsModal] = useState(ssinitialModalState);
  const smsRenderModal = () => {
    const { type, state, data, edit_id, index, title } = smsModal;
    return (
      <Modal
        // size="max-w-2xl"
        title={title}
        open={state}
        setOpen={() =>
          setSmsModal((prev) => ({ ...prev, state: false, edit_id: "" }))
        }
      >
        <Formik
          initialValues={{
            phone_number: edit_id.length === 10 ? "+91" + edit_id : edit_id,
          }}
          enableReinitialize={true}
          onSubmit={async (values) => {
            //TODO: Add optimize code here
            try {
              const headers = dispatch(getHeaders());
              const { data } = await axios.post(
                `${api}/api/therapist/appointment/send-session/payment-link/${index}`,
                {
                  phone_number: "+" + values.phone_number,
                },
                { headers }
              );

              setSmsModal((prev) => ({ ...prev, state: false, edit_id: "" }));
              toast.success("Message Sent");
            } catch (error) {
              const msg = error.response.data.message;
              toast.error(msg);
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setValues,
            handleSubmit,
          }) => (
            <div className="flex w-max gap-2 Z-[9999]">
              <form className="flex gap-2" onSubmit={handleSubmit}>
                <div>
                  <PhoneInput
                    country={"in"}
                    value={values.phone_number}
                    onChange={(phone) =>
                      setValues({ ...values, phone_number: phone })
                    }
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                  />
                </div>
                <button
                  className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  type="submit"
                >
                  send Payment Link
                </button>
              </form>
            </div>
          )}
        </Formik>
      </Modal>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {smsRenderModal()}
      <SendLinkModal
        paymentId={markPaidId}
        showModal={showModal}
        setShowModal={setShowModal}
        setPaid={setPaid}
      />
      <ConfirmationModal
        open={sconfirmationModal.status}
        setOpen={setsConfirmationModal}
        title=" "
        onSend={() => onSend(sconfirmationModal.id)}
        confirmationButtonText="Send"
        confirmationButtonColor="indigo"
        description="Do you want to send the payment link?"
      />
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        title=" "
        sendInvoice={() => sendInvoice(confirmationModal.id)}
        confirmationButtonText="Send"
        confirmationButtonColor="indigo"
        description="Do you want to send the Invoice link?"
      />

      <div className="sm:flex sm:items-center mb-5 space-x-4">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Payment Logs</h1>
        </div>

        <select
          value={filterInstallment}
          onChange={(e) => setFilterInstallment(e.target.value)}
          className="block w-full mt-3 sm:mt-0 sm:w-1/4 px-4 py-2 pr-8 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="">Installments</option>
          <option value="FIRST">First Installment</option>
          <option value="SECOND">Second Installment</option>
        </select>

        <select
          value={filterPaymentSuccess}
          onChange={(e) => setFilterPaymentSuccess(e.target.value)}
          className="block w-full mt-3 sm:mt-0 sm:w-1/4 px-4 py-2 pr-8 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="">Payments</option>
          <option value="true">Successful Payments</option>
          <option value="false">Pending/Failed Payments</option>
        </select>

        <button
          onClick={downloadExcel}
          className="inline-flex items-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        >
          Download Excel
        </button>
      </div>

      {paymentLog.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={therapist_payment_columns({
            markPaid,
            setShowModal,
            setSmsModal,
          })}
          data={paymentLog.data}
          fetch={async (e) => {
            if (!filterInstallment && filterPaymentSuccess === "") {
              setQuery(e);
              await getPaymentLogs(e);
            }
          }}
        />
      )}
    </div>
  );
};

export default PaymentLogs;

export function SendLinkModal({ paymentId, setPaid, showModal, setShowModal }) {
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const [paySrc, setPaySrc] = useState("");
  const [showError, setShowError] = useState(false);
  const initialValues = {
    payment_id: paymentId,
    payment_source: "",
  };
  const dispatch = useDispatch();
  const markPaid = async () => {
    //TODO: Add dispach here for marking paid
    //
    // const body = {
    //   payment_id: paymentId,
    //   payment_source: paySrc,
    // };
    await markingPaid(paymentId);
    setConfirmationModal({ id: null, status: false });
    setShowModal(false);
    setPaySrc("");
    setShowError(false);

    // dispatch(getAllPayments());
    setPaid(true);
  };
  async function markingPaid(id) {
    const headers = await dispatch(getHeaders());
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/therapist/appointment/payment/${id}`,
        {},
        { headers }
      );
      const { data } = response;
      if (data) {
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
  const markPaidConfirmation = () => {
    if (paySrc === "") {
      setShowError(true);
      return;
    }
    setConfirmationModal({ status: true });
    setShowError(false);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: markPaidSchema,

      onSubmit: (values, action) => {
        markPaidConfirmation();
        //TODO: Optimize code here
        action.resetForm();
        setPaid(true);
      },
    });
  // console.log(errors)

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShowModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Mark Paid
                    </Dialog.Title>
                    <div className="mt-2 w-full flex items-center justify-center">
                      <ConfirmationModal
                        open={confirmationModal.status}
                        setOpen={setConfirmationModal}
                        title=" "
                        markPaid={markPaid}
                        confirmationButtonText="Yes"
                        firstBtn="No"
                        confirmationButtonColor="indigo"
                        description="Do you want to mark this payment as paid?"
                      />
                      <form className="w-[80%]" onSubmit={handleSubmit}>
                        <div className="mt-2 flex items-center justify-between">
                          <select
                            id="payment_source"
                            name="payment_source"
                            onChange={(e) => setPaySrc(e.target.value)}
                            onBlur={handleBlur}
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                          >
                            <option>Payment Sources</option>
                            <option value={"RAZORPAY"}>Razorpay</option>
                            <option value={"CASH"}>Cash</option>
                            <option value={"UPI"}>UPI</option>
                            <option value={"CARD"}>Card</option>
                            <option value={"NET_BANKING"}>Net Banking</option>
                            <option value={"CHEQUE"}>Cheque</option>
                          </select>
                        </div>

                        <div>
                          <div className="mt-5 sm:mt-6">
                            <button
                              type="submit"
                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              onClick={() => {
                                markPaidConfirmation();
                              }}
                            >
                              Mark As Paid
                            </button>
                            {showError && (
                              <p className="text-red-500 text-sm">
                                Please select a payment source
                              </p>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
