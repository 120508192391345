import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { ReferralSchema } from "../../../schemas";
import Breadcrumb from "../../../components/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addReferral,
  getReferralWithId,
  updateReferral,
  getAllReferral
} from "../../../redux/referral/action";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";

const Addreferral = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pages = [{ title: "Add Referral", href: "/add-referral" }];

  const { loading, referralData } = useSelector((state) => state.referral);
  const { id: referralId } = useParams();

  const initialValues = {
    name: "",
    type: "",
  };
  const [formState, setFormState] = useState(initialValues);

  useEffect(() => {
    document.title = "New Horizon | Add-Referral";
    if (referralId) dispatch(getReferralWithId(referralId));
  }, [referralId]);

  useEffect(() => {
    if (referralId && referralData) {
      const newFormState = {
        name: referralData.name,
        type: referralData.type,
      };
      setFormState(newFormState);
    }
  }, [referralId, referralData]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: ReferralSchema,
      onSubmit: (values, action) => {
        const body = { name: values.name, type: values.type };
        if (referralId) {
          dispatch(updateReferral(referralId, body)).then(() => {
            dispatch(getAllReferral());
            navigate("/referrals");
          });
        } else {
          dispatch(addReferral(body)).then(() => {
            dispatch(getAllReferral()); 
            navigate("/referrals");
          });
        }
        action.resetForm();
      },
    });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
      </div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {referralId ? "Update Referral" : "Add Referral"}
          </h1>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="off"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.name && touched.name ? (
                      <p className="text-red-700">{errors.name}</p>
                    ) : null}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="type"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Type
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      id="type"
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    >
                      <option value="">Select a Type</option>
                      <option value="DOCTOR">Doctor</option>
                      <option value="ORGANISATION">Organization</option>
                      <option value="SCHOOL">School Name</option>
                      <option value="COUNSELLER">Counseller</option>
                      <option value="SOCIAL_MEDIA">Social media</option>
                      <option value="WOM">Word of Mouth</option>
                    </select>
                    {errors.type && touched.type ? (
                      <p className="text-red-700">{errors.type}</p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Addreferral;
