import { React, useState, useRef } from 'react'
import { Switch } from '@headlessui/react'
import Breadcrumb from "../../../components/Breadcrumb";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';

function locationSpacificeRole(...location) {
    return location.filter(Boolean).join(' ')
}
const people = [
    {
        name: "user",
        status: false,
        email: "user@gmail.com"
    },
    {
        name: "Agricator User",
        status: false,
        email: "aguser@gmail.com"
    },
    {
        name: "Admin",
        status: false,
        email: "admin@gmail.com"
    },
    {
        name: "Super Admin",
        status: false,
        email: "sadmin@gmail.com"

    }
]

const AddRole = ({ roleList, setRolesList }) => {
    const pages = [{ title: "Add Role", href: "/add-role" }];
    const roleRef = useRef();
    const prevLocation = useLocation();
    const [location, setlocation] = useState(false)
    const [roleStatus, setroleStatus] = useState(false)
    const [roleName, setroleName] = useState(null)
    const navigate = useNavigate();

    // console.log(prevLocation.pathname.split("/")[2])
    // const newArrListId = prevLocation.pathname.split("/")[2];
    // let newArrList = roleList;
    // newArrList.forEach((val, key) => {
    //     if(val.id == newArrListId){
    //         // roleRef.current.value = val.role;
    //     }
    // });

    // console.log("newArrList", newArrList);
    const handleSubmit = event => {
        event.preventDefault();
        let payload = {
            role: roleName,
            role_location: location,
            role_status: roleStatus,
            role_email: ""
        }
        setRolesList((prev) => {
            payload.id = parseInt(prev.length + 1);
            return [...prev, payload]
        })
        console.log("niteshpayload", payload)
        setroleName('');
        roleRef.current.value = ""
        setlocation(false)
        setroleStatus(false)
    };


    return (
        <div>
            <Breadcrumb pages={pages} />
            <button
                type="button"
                onClick={() => navigate(-1)}
                className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
                <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span> Back
            </button>
            <div className="sm:flex-auto mb-5">
                <h1 className="text-xl font-semibold text-gray-900">Add Role</h1>
            </div>
            <div className='border border-gray-300 rounded-md px-8 py-8 mb-5'>
                <div className='flex items-center gap-5' >
                    <div className='w-full flex items-center'>
                        <label htmlFor="role" className="block text-sm font-medium text-gray-700 mr-5">
                            Role
                        </label>
                        <div className="mt-1 w-full">
                            <input
                                type="text"
                                name={roleName}
                                id="role"
                                ref={roleRef}
                                onChange={event => setroleName(event.target.value)}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="Role Name"
                            />
                        </div>
                    </div>
                    <div className='flex w-full items-center'>
                        <label htmlFor="role" className="block text-sm font-medium text-gray-700 mr-5">
                            Location Specific Role
                        </label>
                        <Switch
                            checked={location}
                            onChange={setlocation}
                            className={locationSpacificeRole(
                                location ? 'bg-indigo-600' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                            )}
                        >
                            <span className="sr-only">Use setting</span>
                            <span
                                aria-hidden="true"
                                className={locationSpacificeRole(
                                    location ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                )}
                            />
                        </Switch>
                    </div>
                    <div className='flex w-full items-center justify-center'>
                        <label htmlFor="role" className="block text-sm font-medium text-gray-700 mr-5">
                            Status
                        </label>
                        <Switch
                            checked={roleStatus}
                            onChange={setroleStatus}
                            className={locationSpacificeRole(
                                roleStatus ? 'bg-indigo-600' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                            )}
                        >
                            <span className="sr-only">Use setting</span>
                            <span
                                aria-hidden="true"
                                className={locationSpacificeRole(
                                    roleStatus ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                )}
                            />
                        </Switch>
                    </div>
                    <div className='block w-full'>
                        <Link
                            onClick={handleSubmit}
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                            Add Role
                        </Link>
                    </div>
                </div>
            </div>
            <div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className='font-bold'>User Management</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div className="px-4 sm:px-6 lg:px-8">
                                <div className="mt-8 flex flex-col">
                                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                                Module
                                                            </th>
                                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                                View
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {people.map((person) => (
                                                            <tr key={person.email}>
                                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                                    {person.name}
                                                                </td>
                                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                                    <div className="flex h-5 items-center">
                                                                        <input
                                                                            id="comments"
                                                                            aria-describedby="comments-description"
                                                                            name="comments"
                                                                            type="checkbox"
                                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    )
}

export default AddRole