import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Table from "../../../components/tables/table";
import { Referrals_columns } from "../../../components/tables/tableheader";
import { deleteReferral, getAllReferral } from "../../../redux/referral/action";
import { toast } from "react-hot-toast";

const Referral = () => {
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });
  const pages = [{ title: "Referral", href: "/referrals" }];

  const dispatch = useDispatch();
  const { loading, list: referralList } = useSelector(
    (state) => state.referral
  );

  const onDeleteOpen = (id) => {
    setConfirmationModal({ id, status: true });
  };

  const onDeleteReferral = (id) => {
    dispatch(deleteReferral(id))
      .then(() => {
        dispatch(getAllReferral()); // Refresh the referral list after deletion
        setConfirmationModal({ id: null, status: false });
      })
      .catch(() => {
        toast.error("Failed to delete referral");
      });
  };

  useEffect(() => {
    document.title = "New Horizon | Referral";
    dispatch(getAllReferral());
  }, [dispatch]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteReferral(confirmationModal.id)}
      />
      <Breadcrumb pages={pages} />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Referrals</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to="/add-referral"
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Referral
          </Link>
        </div>
      </div>

      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={Referrals_columns({ onDeleteOpen })}
          data={referralList}
        />
      )}
    </div>
  );
};

export default Referral;