import React, { Fragment, useState } from 'react';

import { DragAndDrop, Drag, Drop } from './drag-and-drop';
import { reorder } from './drag-and-drop/helper.js';
import { Dialog, Menu, Transition, Listbox, Switch } from '@headlessui/react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from './Array_move';
import { toast } from 'react-toast';
import Field_comp from './Field';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Preview from './Preview';
import { Link, NavLink, resolvePath, useParams } from 'react-router-dom';
import {
  PlusIcon,
  XMarkIcon,
  DocumentTextIcon,
  CheckIcon,
  HashtagIcon,
  ChevronDownIcon,
  FunnelIcon,
  EllipsisVerticalIcon,
  DocumentArrowUpIcon,
  ArrowsPointingOutIcon,
  ArrowSmallRightIcon,
  ChevronUpDownIcon,
} from '@heroicons/react/24/outline';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useEffect } from 'react';
import { object } from 'yup';
import axios from 'axios';
import { LabelSharp } from '@mui/icons-material';
import zIndex from '@mui/material/styles/zIndex';

const SortableItem = SortableElement(
  ({
    delete_field_data,
    editmode,
    deletefield,
    identy,
    savedata,
    filldata,
    labels,
    alldata,
    available_fields,
  }) => (
    <Field_comp
      available_fields={available_fields}
      editmode={editmode}
      labels={labels}
      delete_field_data={delete_field_data}
      filldata={filldata}
      savedata={savedata}
      identy={identy}
      deletefield={deletefield}
      alldata={alldata}
    />
  )
);

const SortableList = SortableContainer(
  ({
    lockAxis,
    data,
    savedata,
    deletefield,
    sequence,
    delete_field_data,
    labels,
    available_fields,
  }) => {
    return (
      <tbody className="mt-15 bg-white divide-y divide-gray-200">
        {sequence.map((d, index) => {
          return (
            <SortableItem
              key={`item-${d}_${index}_${data.type}`}
              available_fields={available_fields}
              index={index}
              labels={labels}
              delete_field_data={delete_field_data}
              deletefield={deletefield}
              identy={d}
              savedata={savedata}
              filldata={data[d]}
              alldata={data}
              editmode={data[d].editmode}
            />
          );
        })}
      </tbody>
    );
  }
);

export default function Example() {
  const [questionModal, setquestionModal] = useState(false);
  const [preview, set_preview] = useState(false);
  const [editmode, seteditmode] = useState(false);
  const [previewdata, setpreviewdata] = useState({});
  const [sequence, setsequence] = useState([]);
  const [labels, setlabels] = useState([]);
  const [available_fields, set_avail] = useState({});
  const [data, setdata] = useState({});
  const [edited_data, set_edited_data] = useState({});
  const { id: formgroup } = useParams();
  const [loading, setloading] = useState(true);

  let [counter, setcounter] = useState(0);

  async function onSortEnd(pos) {
    if (pos.oldIndex != pos.newIndex) {
      let new_values = arrayMove(sequence, pos.oldIndex, pos.newIndex);
      new_values = new_values.map((val) => parseInt(val));

      await axios
        .put(
          `${process.env.REACT_APP_API_URL}/api/form/form-master/form-group/${formgroup}`,
          {
            form_field_sequence: new_values,
          }
        )
        .then((data) => {
          setsequence(data.data.data.form_field_sequence);
        });
    }
  }
  const fields = {
    ' ': '',
    '  ': '',
    // fieldname: (
    //   <input
    //     type="text"
    //     disabled={true}
    //     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
    //     placeholder="John"
    //     required
    //   />
    // ),
    label: (
      <input
        type="text"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        placeholder="John"
        required
      />
    ),
    type: (
      <div class=" w-100 p-0">
        <div class=" xl:w-96">
          <select
            class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            aria-label="Default select example"
          >
            <option selected>text</option>
            <option>file</option>
            <option>number</option>
            <option>date-time</option>
            <option>radio</option>
            <option>checkbox</option>
            <option>select</option>
            <option>file</option>
            <option>text-area</option>
            <option>email</option>
            <option>password</option>
          </select>
        </div>
      </div>
    ),
    // placeholder: (
    //   <input
    //     type="text"
    //     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
    //     placeholder="John"
    //     required
    //   />
    // ),
    required: (
      <div class="flex justify-center">
        <div class="form-check form-switch">
          <input
            class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
            type="checkbox"
            role="switch"
          />
        </div>
      </div>
    ),

    min: (
      <input
        type="text"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        placeholder="John"
        required
      />
    ),
    max: (
      <input
        type="text"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        placeholder="John"
        required
      />
    ),

    min_length: (
      <input
        type="text"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        placeholder="John"
        required
      />
    ),
    max_length: (
      <input
        type="text"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        placeholder="John"
        required
      />
    ),
    file_Type: (
      <div class="">
        <div class="xl:w-96">
          <select
            class="form-select appearance-none
        block
        w-full
        px-1
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding bg-no-repeat
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            aria-label="Default select example"
          >
            <option selected>image</option>
            <option>video</option>
            <option>media</option>
            <option>excel</option>
            <option>pdf </option>
          </select>
        </div>
      </div>
    ),
    default_Value: (
      <input
        type="text"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
        placeholder="John"
        required
      />
    ),
    // option_sequence: (
    //   <input
    //     type="text"
    //     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
    //     placeholder="John"
    //     required
    //   />
    // ),
  };
  function deletefield(i) {
    let currentstate = data;
    let currentseq = sequence;
    currentseq.splice(currentseq.indexOf(i), 1);
    delete currentstate[i];
    setdata({ ...currentstate });
    setsequence(currentseq);
  }
  useEffect(() => {
    async function get_dat() {
      setloading(true);

      const edit_resp = await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/form/form-builder/${formgroup}`,
          {}
        )
        .then(async (data) => {
          let all_fields = data.data.data;
          setsequence([]);
          setlabels([]);
          let create_data = {};

          Object.keys(all_fields).map((field) => {
            setsequence((ids) => [...ids, all_fields[field].id]);
            setlabels((ids) => [...ids, all_fields[field].label]);

            let target_id = all_fields[field].id;
            create_data[target_id] = {};
            create_data[target_id].id = all_fields[field].id;
            create_data[target_id].ref_id =
              all_fields[field].reference_column_name;
            create_data[target_id].fieldname = all_fields[field].name;
            create_data[target_id].label = all_fields[field].label;
            create_data[target_id].type = all_fields[field].type;
            create_data[target_id].child = all_fields[field].child;
            create_data[target_id].parent = all_fields[field].parent;

            create_data[target_id].placeholder = all_fields[field].placeholder;
            create_data[target_id].defaultValue =
              all_fields[field].defaultValue;

            create_data[target_id].required = all_fields[field].required;
            create_data[target_id].label = all_fields[field].label;
            create_data[target_id].max = parseInt(all_fields[field].max);
            create_data[target_id].min = parseInt(all_fields[field].min);
            let tempobj = {};
            if (all_fields[field].form_field_options) {
              all_fields[field].form_field_options.forEach((opt) => {
                tempobj[opt.id] = opt.option;
              });
            }
            create_data[target_id].options = tempobj;
            create_data[target_id].maxlength = all_fields[field].maxLength;
            create_data[target_id].minlength = all_fields[field].minLength;
            create_data[target_id].file_Type = all_fields[field].fileType;
            create_data[target_id].editmode = 0;
          });
          setloading(false);

          setdata(create_data);
          setpreviewdata(create_data);
          await axios
            .get(
              `${process.env.REACT_APP_API_URL}/api/form/form-builder/available-ref-cols/${formgroup}`
            )
            .then((resp) => {
              set_avail(resp.data.data);
            });
        });
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/form/form-builder/available-ref-cols/${formgroup}`
        )
        .then((resp) => {
          setloading(false);
          set_avail({
            string: resp.data.data.string.length > 0,
            number: resp.data.data.number.length > 0,
            dateTime: resp.data.data.dateTime.length > 0,
          });
        });
    }
    async function delete_field_api() {
      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/api/form/form-builder/${edited_data.delete_ids}`,
          {}
        )
        .then((data) => {
          toast.success('deleted');
          let temp_data = edited_data;
          delete temp_data.delete_ids;
          set_edited_data({ ...temp_data });
          get_dat();
        });
      let currentseq = sequence;
      currentseq.splice(currentseq.indexOf(edited_data.delete_ids), 1);
      setsequence(currentseq);
    }

    async function edit_Data() {
      let edit_dat = Object.keys(edited_data).map((field) => {
        if (!field.includes('new')) {
          let temp_data = { ...edited_data[field] };
          let obj = {};
          obj.id = parseInt(field);
          obj['name'] = temp_data.fieldname;
          if (temp_data.relation_master_table) {
            obj.relation_master_table = temp_data.relation_master_table;
          }
          obj.fileType = temp_data.file_Type;
          if (temp_data.parent == null) {
            obj.parent = '';
          } else {
            obj.parent = temp_data.parent;
          }
          obj.maxLength = parseInt(temp_data.maxlength);
          obj.minLength = parseInt(temp_data.minlength);
          obj.min = String(temp_data.min);
          obj.max = String(temp_data.max);
          obj.defaultValue = temp_data.defaultValue;
          obj.reference_column_name = `${temp_data.ref_id}`;
          if (temp_data.options && Object.keys(temp_data.options).length > 0) {
            obj.form_field_options = Object.keys(temp_data.options).map(
              (opt) => {
                if (temp_data.options[opt]) {
                  return {
                    option: temp_data.options[opt],
                    value: temp_data.options[opt].replace(' ', ''),
                  };
                }
              }
            );
          }
          [
            'options',
            'ref_id',
            'editmode',
            'fieldname',
            'file_Type',
            'maxlength',
            'minlength',
            'min',
            'max',
            'default_Value',
            'parent',
          ].forEach((del_field) => {
            delete temp_data[del_field];
          });

          return { ...obj, ...temp_data };
        } else {
          return;
        }
      });
      let send_data = Object.keys(edited_data).map((field) => {
        if (field.includes('new')) {
          let temp_data = { ...edited_data[field] };
          let obj = {};
          obj['name'] = temp_data.fieldname;
          obj.fileType = temp_data.file_Type;
          if (temp_data.relation_master_table) {
            obj.relation_master_table = temp_data.relation_master_table;
          }
          obj.reference_column_name = '';
          if (temp_data.parent == null) {
            obj.parent = '';
          } else {
            obj.parent = temp_data.parent;
          }
          obj.maxLength = parseInt(temp_data.maxlength);
          obj.minLength = parseInt(temp_data.minlength);
          obj.min = String(temp_data.min);
          obj.max = String(temp_data.max);
          obj.defaultValue = temp_data.default_Value;
          if (temp_data.options && Object.keys(temp_data.options).length > 0) {
            obj.form_field_options = Object.keys(temp_data.options).map(
              (opt) => {
                if (temp_data.options[opt]) {
                  return {
                    option: temp_data.options[opt],
                    value: temp_data.options[opt].replace(' ', ''),
                  };
                }
              }
            );
          }
          [
            'options',
            'editmode',
            'fieldname',
            'file_Type',
            'maxlength',
            'minlength',
            'min',
            'max',
            'default_Value',
            'reference_column_name',
            'parent',
          ].forEach((del_field) => {
            delete temp_data[del_field];
          });

          return { ...obj, ...temp_data };
        } else {
          return;
        }
      });
      if (edit_dat[0]) {
        const edit_resp = await axios
          .put(
            `${process.env.REACT_APP_API_URL}/api/form/form-builder/${formgroup}`,
            edit_dat,
            {}
          )
          .then(async (data) => {
            set_edited_data({});
            get_dat();
            toast.success('edited field successfully');
          });
      }
      if (send_data[0]) {
        setsequence([]);
        const resp = await axios
          .put(
            `${process.env.REACT_APP_API_URL}/api/form/form-builder/${formgroup}`,
            send_data
          )
          .then((resp) => {
            set_edited_data({});
            get_dat();
            toast.success('new field added');
          });
        if (resp?.data?.type == 'success') {
          toast.success('Updated');
        }
      }
    }
    if (Object.keys(edited_data).includes('delete_ids')) {
      delete_field_api();
    } else {
      edit_Data();
    }
  }, [edited_data]);
  useEffect(() => {
    async function get_dat() {
      const edit_resp = await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/form/form-builder/${formgroup}`,

          {}
        )
        .then((data) => {
          let all_fields = data.data.data;

          let create_data = {};

          Object.keys(all_fields).map((field) => {
            setsequence((ids) => [...ids, all_fields[field].id]);
            setlabels((ids) => [...ids, all_fields[field].label]);

            let target_id = all_fields[field].id;
            create_data[target_id] = {};
            create_data[target_id].id = all_fields[field].id;
            create_data[target_id].fieldname = all_fields[field].name;
            create_data[target_id].defaultValue =
              all_fields[field].defaultValue;

            create_data[target_id].label = all_fields[field].label;
            create_data[target_id].child = all_fields[field].child;
            create_data[target_id].parent = all_fields[field].parent;
            create_data[target_id].type = all_fields[field].type;
            create_data[target_id].placeholder = all_fields[field].placeholder;
            create_data[target_id].ref_id =
              all_fields[field].reference_column_name;
            create_data[target_id].required = all_fields[field].required;
            create_data[target_id].label = all_fields[field].label;
            create_data[target_id].max = parseInt(all_fields[field].max);
            create_data[target_id].min = parseInt(all_fields[field].min);
            let tempobj = {};
            if (all_fields[field].form_field_options) {
              all_fields[field].form_field_options.forEach((opt) => {
                tempobj[opt.id] = opt.option;
              });
            }
            create_data[target_id].options = tempobj;
            create_data[target_id].maxlength = all_fields[field].maxLength;
            create_data[target_id].minlength = all_fields[field].minLength;
            create_data[target_id].file_Type = all_fields[field].fileType;
            create_data[target_id].editmode = 0;
          });
          setdata(create_data);
          setpreviewdata(create_data);
        });
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/form/form-builder/available-ref-cols/${formgroup}`
        )
        .then((resp) => {
          setloading(false);
          set_avail({
            string: resp.data.data.string.length > 0,
            number: resp.data.data.number.length > 0,
            dateTime: resp.data.data.dateTime.length > 0,
          });
        });
    }
    get_dat();
  }, []);
  function createfield() {
    let check_editmode = Object?.values(data)?.map((d) => {
      if (d?.editmode == 1) {
        return true;
      }
    });
    if (!check_editmode?.includes(true)) {
      setcounter(counter + 1);
      setsequence((ids) => [...ids, 'new' + counter]);
      let def_field = available_fields?.string
        ? 'text'
        : available_fields?.number
        ? 'number'
        : available_fields?.dateTime
        ? 'date'
        : '';
      setdata({
        ...data,
        ['new' + counter]: {
          fieldname: '',
          label: '',
          type: def_field,
          placeholder: '',
          required: false,
          max: '',
          min: '',
          child: false,
          parent: null,
          options: '',
          maxlength: '',
          minlength: '',
          file_Type: '',
          default_Value: '',
          editmode: 1,
        },
      });
    } else {
      toast.warn('please save field first');
    }
  }

  function toggle_preview() {
    let flags = Object.keys(data).map((id) => {
      return String(id).includes('new');
    });
    if (!flags.includes(true)) {
      set_preview(!preview);
    } else {
      toast.error('please save field first');
    }
  }
  function savedata(getdata, target) {
    let obj = data[target];
    obj = { ...obj, ...getdata };
    set_edited_data({ ...edited_data, [target]: obj });
    setpreviewdata({ ...data, [target]: obj });
  }

  function delete_field_data(identy) {
    set_edited_data({ ...edited_data, delete_ids: identy });
  }
  return (
    <>
      <Transition.Root show={questionModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setquestionModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto"></div>
        </Dialog>
      </Transition.Root>
      {}
      {preview ? (
        <Preview
          fields={previewdata}
          closepreview={toggle_preview}
          sequence={sequence}
        />
      ) : null}
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <div>
          <div
            style={{ zIndex: '10' }}
            className="-mt-6 -ml-8 p-3 fixed flex bg-gray-50 w-10/12 rounded justify-between"
          >
            <button
              type="button"
              onClick={toggle_preview}
              class="inline-block  px-6 right-10 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
            >
              Preview
            </button>
            <button
              class="p-5 bg-transparent mr-5 hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
              onClick={createfield}
            >
              Add Field
            </button>{' '}
          </div>
          <div className="mt-5 flex flex-col">
            <div className="mt-12 overflow-x-auto sm:-mx-3 lg:-mx-4">
              <div className="py-2 align-middle inline-block min-w-full sm:px-4 lg:px-5">
                <div className="overflow-hidden">
                  <table className=" min-w-full">
                    <thead className="bg-gray-50  w-full mt-30 py-20 my-5">
                      <tr className="w-full  py-20">
                        {Object.keys(fields).map((key) => (
                          <th
                            scope="col"
                            className="py-2 px-4 uppercase text-sm"
                          >
                            {key.replace('_', '\n')}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <SortableList
                      axis="y"
                      lockAxis="y"
                      pressDelay={100}
                      available_fields={available_fields}
                      data={data}
                      labels={labels}
                      sequence={sequence}
                      delete_field_data={delete_field_data}
                      savedata={savedata}
                      deletefield={deletefield}
                      onSortEnd={onSortEnd}
                    />
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ margin: '1rem' }}
            className="flex flex-row justify-center items-center"
          >
            {/* <button
          class="p-5 ml-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
          onClick={save_send}
        >
          Save
        </button> */}
          </div>
        </div>
      )}
    </>
  );
}
