import { Fragment, useState } from "react";
import {
  Link,
  NavLink,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { ToastContainer } from "react-toast";
import { FiSend, FiTarget } from "react-icons/fi";
import { RiGroupLine } from "react-icons/ri";
import { AiOutlineComment, AiOutlineUsergroupAdd } from "react-icons/ai";
import {
  MdOutlineClass,
  MdDomainVerification,
  MdOutlineAssessment,
  MdWorkOutline,
  MdOutlinePayment,
  MdReportOff,
} from "react-icons/md";
import Brandlogo from "../assets/logo_with_name.png";

import {
  Bars3BottomLeftIcon,
  BellIcon,
  CalendarIcon,
  tagIcon,
  UsersIcon,
  XMarkIcon,
  UserGroupIcon,
  MapPinIcon,
  UserIcon,
  InformationCircleIcon,
  AdjustmentsHorizontalIcon,
  WrenchScrewdriverIcon,
  HandThumbUpIcon,
  BriefcaseIcon,
  MegaphoneIcon,
  DocumentChartBarIcon,
  UserPlusIcon,
  SquaresPlusIcon,
  BuildingLibraryIcon,
  BuildingOffice2Icon,
} from "@heroicons/react/24/outline";
import { AiOutlineTag, AiOutlineMedicineBox } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { getHeaders, handleLogout } from "../redux/auth/actions";
import Notifications from "../container/pages/notifications/Notifications";
import { useSelector } from "react-redux";
import { logo } from "../assets/index";
import axios from "axios";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { BsPaperclip } from "react-icons/bs";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DashboardLayout = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  const role = user?.role;
  const roles = {
    SUPER_ADMIN: [
      {
        name: "OPD Consultation",
        submenu: true,
        icon: CalendarIcon,
        submenuItems: [
          {
            name: "OPD Calendar",
            href: "/appointment",
            icon: UserGroupIcon,
            current: false,
          },

          {
            name: "OPD Appointment",
            href: "/opd-report",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Payments",
            href: "/payments",
            icon: UserIcon,
            current: false,
          },
          {
            name: "Cancelled Appointment Logs",
            href: "/cancel-opd",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Cancelled Evaluation Logs",
            href: "/cancel-evaluation",
            icon: UserGroupIcon,
            current: false,
          },
        ],
      },

      {
        name: "NHDP & E-nable Enrolment",
        submenu: true,
        icon: CalendarIcon,
        submenuItems: [
          {
            name: "Appointments",
            href: "/therapist-master",
            icon: UserGroupIcon,
            current: false,
          },

          {
            name: "Therapist Master",
            href: "/therapist",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Therapist Appointment Logs",
            href: "/therapist-appointment-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Therapist Logs",
            href: "/therapist-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Session Canceled Logs",
            href: "/session-canceled-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Evaluation Canceled Logs",
            href: "/evaluation-canceled-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "OPD Countdown",
            href: "/countdown-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Evaluation Logs",
            href: "/appointment-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Evaluation Payments",
            href: "/therapist-payment",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Patient Enrollment",
            href: "/therapist-enrolment",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Payment Logs",
            href: "/therapist-payment-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Patient Recommendation",
            href: "/patient-recommendation",
            icon: AiOutlineTag,
            current: false,
          },
        ],
      },
      {
        name: "Evaluation Master",
        submenu: true,
        icon: CalendarIcon,
        submenuItems: [
          {
            name: "Master",
            href: "/evaluation-master",
            icon: UserGroupIcon,
            current: false,
          },

          {
            name: "Evaluation Department",
            href: "/evaluation-department",
            icon: UserGroupIcon,
            current: false,
          },
        ],
      },
      {
        name: "Pedigree Chart",
        href: "https://pedigree.newhorizonscdc.in",
        icon: BsPaperclip,
        current: true,
      },
      { name: "User", href: "/users", icon: UsersIcon, current: false },
      {
        name: "Plan Dashboard Logs",
        href: "/plan-dashboard",
        icon: AiOutlineUsergroupAdd,
        current: false,
      },
      {
        name: "Activity",
        href: "/activity-master",
        icon: AiOutlineUsergroupAdd,
        current: false,
      },
      {
        name: "Forms",
        submenu: true,
        icon: InformationCircleIcon,
        current: false,
        submenuItems: [
          {
            name: "Master",
            href: "/forms",
            icon: UserGroupIcon,
            current: false,
          },
          // {
          //   name: "Form Builder (UI)",
          //   href: "/form-builder",
          //   icon: UsersIcon,
          //   current: false,
          // },
          // {
          //   name: "main Form",
          //   href: "/form",
          //   icon: UsersIcon,
          //   current: false,
          // },
        ],
      },

      {
        name: "Master",
        submenu: true,
        current: false,
        icon: AdjustmentsHorizontalIcon,
        submenuItems: [
          {
            name: "Patient",
            href: "/patients",
            icon: UserGroupIcon,
            current: false,
          },

          {
            name: "Location",
            href: "/locations",
            icon: MapPinIcon,
            current: false,
          },
          {
            name: "Role",
            href: "/role",
            icon: UsersIcon,
            current: false,
          },
          {
            name: "Referral",
            href: "/referrals",
            icon: FiSend,
            current: false,
          },
          {
            name: "Medicine",
            href: "/medicine-list",
            icon: BriefcaseIcon,
            current: false,
          },
          { name: "Doctor", href: "/doctors", icon: UserIcon, current: false },

          {
            name: "Holiday",
            href: "/holiday",
            icon: UserPlusIcon,
            current: false,
          },
          {
            name: "Modules",
            href: "/modules",
            icon: UserPlusIcon,
            current: false,
          },
          {
            name: "Break Time",
            href: "/break-time",
            icon: UserPlusIcon,
            current: false,
          },
          {
            name: "Global Settings",
            href: "/global-settings",
            icon: WrenchScrewdriverIcon,
            current: false,
          },
          {
            name: "Department",
            href: "/department-master",
            icon: MdOutlineClass,
            current: false,
          },
          {
            name: "Goal",
            href: "/goal-master",
            icon: FiTarget,
            current: false,
          },
          {
            name: "Goal Logs",
            href: "/get-all-goals-log",
            icon: FiTarget,
            current: false,
          },
          // {
          //   name: "Category",
          //   href: "/category-master",
          //   icon: AiOutlineTag,
          //   current: false,
          // },
          {
            name: "Age Group",
            href: "/age-group-master",
            icon: RiGroupLine,
            current: false,
          },
          {
            name: "Domain",
            href: "/domain-master",
            icon: MdDomainVerification,
            current: false,
          },
          {
            name: "Recommendation",
            href: "/recommendation",
            icon: HandThumbUpIcon,
            current: false,
          },

          // {
          //   name: "Prescription",
          //   href: "/prescription",
          //   icon: ClipboardDocumentIcon,
          //   current: false,
          // },
          {
            name: "Medicine",
            href: "/medicine-master",
            icon: AiOutlineMedicineBox,
            current: false,
          },
          {
            name: "Medicine Name",
            href: "/medicine-name-master",
            icon: AiOutlineMedicineBox,
            current: false,
          },
          {
            name: "Stregth",
            href: "/strength-master",
            icon: AiOutlineMedicineBox,
            current: false,
          },
          {
            name: "Formulation",
            href: "/formulation-master",
            icon: AiOutlineMedicineBox,
            current: false,
          },
          {
            name: "Route of Administration",
            href: "/Route-of-administration-master",
            icon: AiOutlineMedicineBox,
            current: false,
          },
          // {
          //   name: "Medicine",
          //   href: "/medicine",
          //   icon: AiOutlineMedicineBox,
          //   current: false,
          // },
          {
            name: "Mchat",
            href: "/mchat",
            icon: AiOutlineMedicineBox,
            current: false,
          },
          {
            name: "Program",
            href: "/program-master",
            icon: MdWorkOutline,
            current: false,
          },
          {
            name: "Program Session",
            href: "/program-session",
            icon: MdOutlineAssessment,
            current: false,
          },
          {
            name: "Notifications",
            href: "/notifications",
            icon: BellIcon,
            current: false,
          },
          {
            name: "Announcement",
            href: "/announcement",
            icon: MegaphoneIcon,
            current: false,
          },
        ],
      },
      {
        name: "Reports",
        submenu: true,
        icon: DocumentChartBarIcon,
        current: false,
        submenuItems: [
          {
            name: "Patient Session",
            href: "/patient-session-logs",
            icon: UserPlusIcon,
            current: false,
          },
          // {
          //   name: "Collection Report",
          //   href: "/patient-collection",
          //   icon: UserPlusIcon,
          //   current: false,
          // },
          {
            name: "Report",
            href: "/new-report",
            icon: UserPlusIcon,
            current: false,
          },
          {
            name: "Patients",
            href: "/patients-report",
            icon: UserPlusIcon,
            current: false,
          },
          {
            name: "OPD",
            href: "/opdreport",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "QPR Sheet",
            href: "/qpr-sheet",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "MIS session slot",
            href: "/mis-session-slot-report",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "MIS session slot conversion",
            href: "/mis-session-slot-report-conversion",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "MIS Re Enrollment",
            href: "/mis-re-enrollment",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "Specialist Performance",
            href: "/specialist-performance",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "Patient's and Sessions",
            href: "/patient-and-session-report",
            icon: BuildingLibraryIcon,
            current: false,
          },
          // {
          //   name: "Consolidate Report",
          //   href: "/consolidate-report",
          //   icon: BuildingOffice2Icon,
          //   current: false,
          // },
        ],
      },
      {
        name: "RedFlag",
        submenu: true,
        icon: InformationCircleIcon,
        current: false,
        submenuItems: [
          {
            name: "Payments",
            href: "/redflag-payments",
            icon: MdOutlinePayment,
            current: false,
          },
          {
            name: "All Comments",
            href: "/redflag-all-comments",
            icon: AiOutlineComment,
            current: false,
          },
        ],
      },
    ],

    DOCTOR: [
      {
        name: "OPD Appointment",
        href: "/opd-report",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Upload Pedigree Chart",
        href: "/upload-pedigree",
        icon: BsPaperclip,
        current: false,
      },
      {
        name: "Patient",
        href: "/patients",
        icon: UserGroupIcon,
        current: false,
      },
    ],
    PLAN_MAKER: [
      {
        name: "OPD Appointment",
        href: "/opd-report",
        icon: UserGroupIcon,
        current: false,
      },

      {
        name: "Plan Dashboard Logs",
        href: "/plan-dashboard",
        icon: AiOutlineUsergroupAdd,
        current: false,
      },
      {
        name: "Patient",
        href: "/patients",
        icon: UserGroupIcon,
        current: false,
      },
    ],
    CLINICAL_ADMIN: [
      {
        name: "Payments",
        href: "/redflag-payments",
        icon: MdOutlinePayment,
        current: false,
      },
      {
        name: "OPD Calendar",
        href: "/appointment",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "OPD Appointment",
        href: "/opd-report",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Cancelled Appointment Logs",
        href: "/cancel-opd",
        icon: UserGroupIcon,
        current: false,
      },

      {
        name: "NHDP & E-nable Erolment",
        submenu: true,
        icon: CalendarIcon,
        submenuItems: [
          {
            name: "Appointments",
            href: "/therapist-master",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Therapist Master",
            href: "/therapist",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Therapist Appointment Logs",
            href: "/therapist-appointment-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Therapist Logs",
            href: "/therapist-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Session Canceled Logs",
            href: "/session-canceled-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "OPD Countdown",
            href: "/countdown-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Evaluation Logs",
            href: "/appointment-logs",
            icon: UserGroupIcon,
            current: false,
          },

          // {
          //   name: "Appointments",
          //   href: "/therapist-master",
          //   icon: UserGroupIcon,
          //   current: false,
          // },

          // {
          //   name: "Therapist Master",
          //   href: "/therapist",
          //   icon: UserGroupIcon,
          //   current: false,
          // },
          // {
          //   name: "Therapist Logs",
          //   href: "/therapist-logs",
          //   icon: UserGroupIcon,
          //   current: false,
          // },
          // {
          //   name: "Session Canceled Logs",
          //   href: "/session-canceled-logs",
          //   icon: UserGroupIcon,
          //   current: false,
          // },
          // {
          //   name: "OPD Countdown",
          //   href: "/countdown-logs",
          //   icon: UserGroupIcon,
          //   current: false,
          // },
          // {
          //   name: "Evaluation Logs",
          //   href: "/appointment-logs",
          //   icon: UserGroupIcon,
          //   current: false,
          // },

          {
            name: "Evaluation Payments",
            href: "/therapist-payment",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Patient Enrolment",
            href: "/therapist-enrolment",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Payment Logs",
            href: "/therapist-payment-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Patient Recommendation",
            href: "/patient-recommendation",
            icon: AiOutlineTag,
            current: false,
          },
        ],
      },

      {
        name: "Evaluation Master",
        submenu: true,
        icon: CalendarIcon,
        submenuItems: [
          {
            name: "Master",
            href: "/evaluation-master",
            icon: UserGroupIcon,
            current: false,
          },

          {
            name: "Evaluation Department",
            href: "/evaluation-department",
            icon: UserGroupIcon,
            current: false,
          },
        ],
      },
      {
        name: "Pedigree Chart",
        href: "https://pedigree.newhorizonscdc.in",
        icon: BsPaperclip,
        current: true,
      },
      {
        name: "Plan Dashboard Logs",
        href: "/plan-dashboard",
        icon: AiOutlineUsergroupAdd,
        current: false,
      },
      {
        name: "Activity",
        href: "/activity-master",
        icon: AiOutlineUsergroupAdd,
        current: false,
      },
      {
        name: "Master",
        submenu: true,
        current: false,
        icon: AdjustmentsHorizontalIcon,
        submenuItems: [
          {
            name: "Patient",
            href: "/patients",
            icon: UserGroupIcon,
            current: false,
          },

          {
            name: "Referral",
            href: "/referrals",
            icon: FiSend,
            current: false,
          },
          {
            name: "Medicine",
            href: "/medicine-list",
            icon: BriefcaseIcon,
            current: false,
          },

          {
            name: "Goal",
            href: "/goal-master",
            icon: FiTarget,
            current: false,
          },
          {
            name: "Goal Logs",
            href: "/get-all-goals-log",
            icon: FiTarget,
            current: false,
          },
          // {
          //   name: "Category",
          //   href: "/category-master",
          //   icon: AiOutlineTag,
          //   current: false,
          // },
          {
            name: "Age Group",
            href: "/age-group-master",
            icon: RiGroupLine,
            current: false,
          },

          {
            name: "Recommendation",
            href: "/recommendation",
            icon: HandThumbUpIcon,
            current: false,
          },

          // {
          //   name: "Prescription",
          //   href: "/prescription",
          //   icon: ClipboardDocumentIcon,
          //   current: false,
          // },
          {
            name: "Medicine",
            href: "/medicine-master",
            icon: AiOutlineMedicineBox,
            current: false,
          },
          {
            name: "Medicine Name",
            href: "/medicine-name-master",
            icon: AiOutlineMedicineBox,
            current: false,
          },
          {
            name: "Stregth",
            href: "/strength-master",
            icon: AiOutlineMedicineBox,
            current: false,
          },
          {
            name: "Formulation",
            href: "/formulation-master",
            icon: AiOutlineMedicineBox,
            current: false,
          },
          {
            name: "Route of Administration",
            href: "/Route-of-administration-master",
            icon: AiOutlineMedicineBox,
            current: false,
          },
          // {
          //   name: "Medicine",
          //   href: "/medicine",
          //   icon: AiOutlineMedicineBox,
          //   current: false,
          // },
          {
            name: "Mchat",
            href: "/mchat",
            icon: AiOutlineMedicineBox,
            current: false,
          },
          {
            name: "Program",
            href: "/program-master",
            icon: MdWorkOutline,
            current: false,
          },
          {
            name: "Program Session",
            href: "/program-session",
            icon: MdOutlineAssessment,
            current: false,
          },
          {
            name: "Notifications",
            href: "/notifications",
            icon: BellIcon,
            current: false,
          },
        ],
      },
      {
        name: "Reports",
        submenu: true,
        icon: DocumentChartBarIcon,
        current: false,
        submenuItems: [
          {
            name: "Patient Session",
            href: "/patient-session-logs",
            icon: UserPlusIcon,
            current: false,
          },
          // {
          //   name: "Collection Report",
          //   href: "/patient-collection",
          //   icon: UserPlusIcon,
          //   current: false,
          // },
          {
            name: "Report",
            href: "/new-report",
            icon: UserPlusIcon,
            current: false,
          },
          {
            name: "Patients",
            href: "/patients-report",
            icon: UserPlusIcon,
            current: false,
          },
          {
            name: "OPD",
            href: "/opdreport",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "QPR Sheet",
            href: "/qpr-sheet",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "MIS session slot",
            href: "/mis-session-slot-report",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "MIS session slot conversion",
            href: "/mis-session-slot-report-conversion",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "MIS Re Enrollment",
            href: "/mis-re-enrollment",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "Specialist Performance",
            href: "/specialist-performance",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "Patient's and Sessions",
            href: "/patient-and-session-report",
            icon: BuildingLibraryIcon,
            current: false,
          },
          // {
          //   name: "Consolidate Report",
          //   href: "/consolidate-report",
          //   icon: BuildingOffice2Icon,
          //   current: false,
          // },
        ],
      },
      {
        name: "RedFlag",
        submenu: true,
        icon: InformationCircleIcon,
        current: false,
        submenuItems: [
          {
            name: "Payments",
            href: "/redflag-payments",
            icon: MdOutlinePayment,
            current: false,
          },
          {
            name: "All Comments",
            href: "/redflag-all-comments",
            icon: AiOutlineComment,
            current: false,
          },
        ],
      },
    ],

    CLINICAL_ASSISTANT: [
      {
        name: "OPD Calendar",
        href: "/appointment",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "OPD Appointment",
        href: "/opd-report",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Cancelled Appointment Logs",
        href: "/cancel-opd",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Appointments",
        href: "/therapist-master",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Therapist Master",
        href: "/therapist",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Therapist Logs",
        href: "/therapist-logs",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Session Canceled Logs",
        href: "/session-canceled-logs",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "OPD Countdown",
        href: "/countdown-logs",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Evaluation Logs",
        href: "/appointment-logs",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Evaluation Payments",
        href: "/therapist-payment",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Patient Enrollment",
        href: "/therapist-enrolment",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Payment Logs",
        href: "/therapist-payment-logs",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Patient Recommendation",
        href: "/patient-recommendation",
        icon: AiOutlineTag,
        current: false,
      },
      {
        name: "Evaluation Master",
        submenu: true,
        icon: CalendarIcon,
        submenuItems: [
          {
            name: "Master",
            href: "/evaluation-master",
            icon: UserGroupIcon,
            current: false,
          },

          {
            name: "Evaluation Department",
            href: "/evaluation-department",
            icon: UserGroupIcon,
            current: false,
          },
        ],
      },
      { name: "User", href: "/users", icon: UsersIcon, current: false },
      {
        name: "Plan Dashboard Logs",
        href: "/plan-dashboard",
        icon: AiOutlineUsergroupAdd,
        current: false,
      },
      {
        name: "Activity",
        href: "/activity-master",
        icon: AiOutlineUsergroupAdd,
        current: false,
      },
      {
        name: "Master",
        submenu: true,
        current: false,
        icon: AdjustmentsHorizontalIcon,
        submenuItems: [
          {
            name: "Patient",
            href: "/patients",
            icon: UserGroupIcon,
            current: false,
          },

          {
            name: "Location",
            href: "/locations",
            icon: MapPinIcon,
            current: false,
          },
          {
            name: "Role",
            href: "/role",
            icon: UsersIcon,
            current: false,
          },
          {
            name: "Referral",
            href: "/referrals",
            icon: FiSend,
            current: false,
          },
          {
            name: "Medicine",
            href: "/medicine-list",
            icon: BriefcaseIcon,
            current: false,
          },
          { name: "Doctor", href: "/doctors", icon: UserIcon, current: false },
          // {
          //   name: "Payments",
          //   href: "/payments",
          //   icon: UserIcon,
          //   current: false,
          // },
          // {
          //   name: "Holiday",
          //   href: "/holiday",
          //   icon: UserPlusIcon,
          //   current: false,
          // },
          {
            name: "Modules",
            href: "/modules",
            icon: UserPlusIcon,
            current: false,
          },
          {
            name: "Break Time",
            href: "/break-time",
            icon: UserPlusIcon,
            current: false,
          },
          {
            name: "Global Settings",
            href: "/global-settings",
            icon: WrenchScrewdriverIcon,
            current: false,
          },
          {
            name: "Department",
            href: "/department-master",
            icon: MdOutlineClass,
            current: false,
          },
          {
            name: "Goal",
            href: "/goal-master",
            icon: FiTarget,
            current: false,
          },
          {
            name: "Goal Logs",
            href: "/get-all-goals-log",
            icon: FiTarget,
            current: false,
          },
          // {
          //   name: "Category",
          //   href: "/category-master",
          //   icon: AiOutlineTag,
          //   current: false,
          // },
          {
            name: "Age Group",
            href: "/age-group-master",
            icon: RiGroupLine,
            current: false,
          },
          {
            name: "Domain",
            href: "/domain-master",
            icon: MdDomainVerification,
            current: false,
          },
          {
            name: "Recommendation",
            href: "/recommendation",
            icon: HandThumbUpIcon,
            current: false,
          },

          // {
          //   name: "Prescription",
          //   href: "/prescription",
          //   icon: ClipboardDocumentIcon,
          //   current: false,
          // },
          // {
          //   name: "Medicine",
          //   href: "/medicine-master",
          //   icon: AiOutlineMedicineBox,
          //   current: false,
          // },
          // {
          //   name: "Medicine Name",
          //   href: "/medicine-name-master",
          //   icon: AiOutlineMedicineBox,
          //   current: false,
          // },
          // {
          //   name: "Stregth",
          //   href: "/strength-master",
          //   icon: AiOutlineMedicineBox,
          //   current: false,
          // },
          // {
          //   name: "Formulation",
          //   href: "/formulation-master",
          //   icon: AiOutlineMedicineBox,
          //   current: false,
          // },
          // {
          //   name: "Route of Administration",
          //   href: "/Route-of-administration-master",
          //   icon: AiOutlineMedicineBox,
          //   current: false,
          // },
          // // {
          // //   name: "Medicine",
          // //   href: "/medicine",
          // //   icon: AiOutlineMedicineBox,
          // //   current: false,
          // // },
          // {
          //   name: "Mchat",
          //   href: "/mchat",
          //   icon: AiOutlineMedicineBox,
          //   current: false,
          // },
          // {
          //   name: "Program",
          //   href: "/program-master",
          //   icon: MdWorkOutline,
          //   current: false,
          // },
          {
            name: "Program Session",
            href: "/program-session",
            icon: MdOutlineAssessment,
            current: false,
          },
          {
            name: "Notifications",
            href: "/notifications",
            icon: BellIcon,
            current: false,
          },
          // {
          //   name: "Announcement",
          //   href: "/announcement",
          //   icon: MegaphoneIcon,
          //   current: false,
          // },
        ],
      },
      {
        name: "Reports",
        submenu: true,
        icon: DocumentChartBarIcon,
        current: false,
        submenuItems: [
          {
            name: "Patient Session",
            href: "/patient-session-logs",
            icon: UserPlusIcon,
            current: false,
          },
          {
            name: "Collection Report",
            href: "/patient-collection",
            icon: UserPlusIcon,
            current: false,
          },
          {
            name: "Report",
            href: "/new-report",
            icon: UserPlusIcon,
            current: false,
          },
          {
            name: "Patients",
            href: "/patients-report",
            icon: UserPlusIcon,
            current: false,
          },
          {
            name: "OPD",
            href: "/opdreport",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "QPR Sheet",
            href: "/qpr-sheet",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "MIS session slot",
            href: "/mis-session-slot-report",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "MIS session slot conversion",
            href: "/mis-session-slot-report-conversion",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "MIS Re Enrollment",
            href: "/mis-re-enrollment",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "Specialist Performance",
            href: "/specialist-performance",
            icon: SquaresPlusIcon,
            current: false,
          },
          {
            name: "Patient's and Sessions",
            href: "/patient-and-session-report",
            icon: BuildingLibraryIcon,
            current: false,
          },
          {
            name: "Consolidate Report",
            href: "/consolidate-report",
            icon: BuildingOffice2Icon,
            current: false,
          },
        ],
      },
    ],

    CLINICAL_HEAD: [
      {
        name: "OPD Consultation",
        submenu: true,
        icon: CalendarIcon,
        submenuItems: [
          {
            name: "OPD Calendar",
            href: "/appointment",
            icon: UserGroupIcon,
            current: false,
          },

          {
            name: "OPD Appointment",
            href: "/opd-report",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Cancelled Appointment Logs",
            href: "/cancel-opd",
            icon: UserGroupIcon,
            current: false,
          },
        ],
      },
      {
        name: "Patient",
        href: "/patients",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "NHDP & E-nable Erolment",
        submenu: true,
        icon: CalendarIcon,
        submenuItems: [
          {
            name: "Appointments",
            href: "/therapist-master",
            icon: UserGroupIcon,
            current: false,
          },

          {
            name: "Therapist Master",
            href: "/therapist",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Therapist Logs",
            href: "/therapist-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Session Canceled Logs",
            href: "/session-canceled-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "OPD Countdown",
            href: "/countdown-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Evaluation Logs",
            href: "/appointment-logs",
            icon: UserGroupIcon,
            current: false,
          },
          // {
          //   name: "Evaluation Payments",
          //   href: "/therapist-payment",
          //   icon: UserGroupIcon,
          //   current: false,
          // },
          // {
          //   name: "Patient Enrolment",
          //   href: "/therapist-enrolment",
          //   icon: UserGroupIcon,
          //   current: false,
          // },
          // {
          //   name: "Payment Logs",
          //   href: "/therapist-payment-logs",
          //   icon: UserGroupIcon,
          //   current: false,
          // },
          // {
          //   name: "Patient Recommendation",
          //   href: "/patient-recommendation",
          //   icon: AiOutlineTag,
          //   current: false,
          // },
        ],
      },
      {
        name: "Plan Dashboard Logs",
        href: "/plan-dashboard",
        icon: AiOutlineUsergroupAdd,
        current: false,
      },
      {
        name: "RedFlag",
        submenu: true,
        icon: InformationCircleIcon,
        current: false,
        submenuItems: [
          {
            name: "All Comments",
            href: "/redflag-all-comments",
            icon: AiOutlineComment,
            current: false,
          },
        ],
      },
    ],

    RECEPTION_MANAGER: [
      {
        name: "OPD Consultation",
        submenu: true,
        icon: CalendarIcon,
        submenuItems: [
          {
            name: "OPD Calendar",
            href: "/appointment",
            icon: UserGroupIcon,
            current: false,
          },

          {
            name: "OPD Appointment",
            href: "/opd-report",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Payments",
            href: "/payments",
            icon: UserIcon,
            current: false,
          },
          {
            name: "Cancelled Appointment Logs",
            href: "/cancel-opd",
            icon: UserGroupIcon,
            current: false,
          },
        ],
      },
      {
        name: "NHDP & E-nable Erolment",
        submenu: true,
        icon: CalendarIcon,
        submenuItems: [
          {
            name: "Appointments",
            href: "/therapist-master",
            icon: UserGroupIcon,
            current: false,
          },

          {
            name: "Therapist Master",
            href: "/therapist",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Therapist Logs",
            href: "/therapist-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Session Canceled Logs",
            href: "/session-canceled-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "OPD Countdown",
            href: "/countdown-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Evaluation Logs",
            href: "/appointment-logs",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Evaluation Payments",
            href: "/therapist-payment",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Patient Enrollment",
            href: "/therapist-enrolment",
            icon: UserGroupIcon,
            current: false,
          },
          {
            name: "Payment Logs",
            href: "/therapist-payment-logs",
            icon: UserGroupIcon,
            current: false,
          },
          // {
          //   name: "Patient Recommendation",
          //   href: "/patient-recommendation",
          //   icon: AiOutlineTag,
          //   current: false,
          // },
        ],
      },
      {
        name: "Master",
        submenu: true,
        current: false,
        icon: AdjustmentsHorizontalIcon,
        submenuItems: [
          {
            name: "Patient",
            href: "/patients",
            icon: UserGroupIcon,
            current: false,
          },
          // {
          //   name: "Payments",
          //   href: "/payments",
          //   icon: UserIcon,
          //   current: false,
          // },
          {
            name: "Location",
            href: "/locations",
            icon: MapPinIcon,
            current: false,
          },
          {
            name: "Role",
            href: "/role",
            icon: UsersIcon,
            current: false,
          },
          {
            name: "Referral",
            href: "/referrals",
            icon: FiSend,
            current: false,
          },
          { name: "Doctor", href: "/doctors", icon: UserIcon, current: false },
          // {
          //   name: "Payments",
          //   href: "/payments",
          //   icon: UserIcon,
          //   current: false,
          // },
          {
            name: "Notifications",
            href: "/notifications",
            icon: BellIcon,
            current: false,
          },
        ],
      },
    ],

    THERAPIST: [
      {
        name: "OPD Appointment",
        href: "/opd-report",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Therapist Logs",
        href: "/therapist-logs",
        icon: UserGroupIcon,
        current: false,
      },
      {
        name: "Therapist Appointment Logs",
        href: "/therapist-appointment-logs",
        icon: UserGroupIcon,
        current: false,
      },
    ],
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [submenuOpen, setsubmenuOpen] = useState({
    appointment: false,
    forms: false,
    master: false,
    RedFlag: false,
  });

  const onLogout = () => {
    dispatch(handleLogout());
    Navigate("/login");
  };
  const location = useLocation();
  // console.log();
  const handleProfile = () => {
    // Navigate("/profile");
    console.log("nitesh");
  };

  const userNavigation = [
    { name: "Profile", href: "/profile", onclick: handleProfile, click: false },
    { name: "Logout", href: "#", onclick: onLogout, click: true },
  ];
  const [notify, setNotify] = useState({
    loading: true,
    data: [],
  });
  const dispach = useDispatch();
  const getAllLocations = async () => {
    const headers = await dispach(getHeaders());
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/notification/count`,
        {
          headers: headers,
        }
      );
      setNotify({
        loading: false,
        data: response.data.data,
      });
    } catch (error) { }
  };
  useEffect(() => {
    getAllLocations();
  }, [location.pathname]);
  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-30 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-indigo-700 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img className="h-8 w-auto" src={logo} alt="Your Company" />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {roles[role].map((menu, index) =>
                        menu?.current && menu?.current === true ? (
                          <>
                            <a
                              key={index}
                              href={menu.href}
                              className={classNames(
                                menu.current
                                  ? "bg-indigo-800 text-white"
                                  : "text-indigo-100 hover:bg-indigo-600",
                                "group flex items-center px-2 py-2  font-medium rounded-md"
                              )}
                            >
                              <menu.icon
                                className="mr-3 h-6 w-6 flex-shrink-0 text-indigo-300"
                                aria-hidden="true"
                              />
                              {menu.name}
                            </a>
                          </>
                        ) : (
                          <>
                            <NavLink
                              key={index}
                              to={menu.href}
                              // onClick={() => {
                              //   menu.submenu && setsubmenuOpen(!submenuOpen);
                              // }}
                              className={classNames(
                                menu.current
                                  ? "bg-indigo-800 text-white"
                                  : "text-indigo-100 hover:bg-indigo-600",
                                "group flex items-center px-2 py-2  font-medium rounded-md"
                              )}
                            >
                              <menu.icon
                                className="mr-3 h-6 w-6 flex-shrink-0 text-indigo-300"
                                aria-hidden="true"
                              />
                              {menu.name}
                              {menu.submenu && (
                                <ChevronDownIcon
                                  className={` ${submenuOpen[menu.name.toLowerCase()]
                                    ? "-rotate-0"
                                    : "-rotate-90"
                                    } mr-3 h-6 w-6 flex-shrink-0 text-sky-800 duration-150`}
                                  aria-hidden="true"
                                />
                              )}
                            </NavLink>
                            {menu.submenu && (
                              <div>
                                {menu.submenuItems.map((submenuItem, index) => (
                                  <Link
                                    key={index}
                                    to={submenuItem.href}
                                    className={classNames(
                                      menu.current
                                        ? "bg-indigo-800 text-white"
                                        : "text-indigo-100 hover:bg-indigo-600",
                                      "group flex items-center  px-2 py-2 text-xs font-normal rounded-md"
                                    )}
                                  >
                                    <submenuItem.icon
                                      className="ml-5 mr-3 h-5 w-6 flex-shrink-0 text-indigo-300"
                                      aria-hidden="true"
                                    />
                                    {submenuItem.name}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </>
                        )
                      )}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden z-40 md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col overflow-y-auto bg-indigo-700 pt-5">
            <div className="flex flex-shrink-0 items-center px-4">
              <img
                className="h-20 w-auto object-contain "
                src={logo}
                alt="Your Company"
              />
            </div>
            <div className="mt-5 flex flex-1 flex-col">
              <nav className="flex-1 space-y-1 px-2 pb-4">
                {roles[role].map((menu, index) =>
                  menu.current && menu.current === true ? (
                    <>
                      <a
                        href={menu.href}
                        title="_blank"
                        className={classNames(
                          window.location.pathname === menu.href
                            ? "bg-indigo-800 text-white"
                            : "text-indigo-100 hover:bg-indigo-600",
                          "group flex items-center px-2 py-2  font-medium rounded-md"
                        )}
                      >
                        <menu.icon
                          className="mr-3 h-6 w-6 flex-shrink-0 text-indigo-300"
                          aria-hidden="true"
                        />
                        {menu.name}
                        {menu.submenu && (
                          <ChevronDownIcon
                            className={` ${submenuOpen[menu.name.toLowerCase()]
                              ? "-rotate-0"
                              : "-rotate-90"
                              } mr-3 h-6 w-6 flex-shrink-0 text-indigo-150 duration-150`}
                            aria-hidden="true"
                          />
                        )}
                      </a>
                      {menu.submenu && submenuOpen[menu.name.toLowerCase()] && (
                        <div>
                          {submenuOpen &&
                            menu.submenuItems.map((submenuItem, index) => (
                              <Link
                                to={submenuItem.href}
                                className={classNames(
                                  window.location.pathname === submenuItem.href
                                    ? "bg-indigo-800 text-white"
                                    : "text-indigo-100 hover:bg-indigo-600",
                                  "group flex items-center  px-2 py-2  font-medium rounded-md"
                                )}
                                key={index}
                              >
                                <submenuItem.icon
                                  className="ml-5 mr-3 h-5 w-6 flex-shrink-0 text-indigo-300"
                                  aria-hidden="true"
                                />
                                {submenuItem.name}
                              </Link>
                            ))}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <Link
                        key={index}
                        to={menu.href}
                        onClick={() => {
                          setsubmenuOpen({
                            ...submenuOpen,
                            [menu.name.toLowerCase()]:
                              !submenuOpen[menu.name.toLowerCase()],
                          });
                        }}
                        className={classNames(
                          window.location.pathname === menu.href
                            ? "bg-indigo-800 text-white"
                            : "text-indigo-100 hover:bg-indigo-600",
                          "group flex items-center px-2 py-2  font-medium rounded-md"
                        )}
                      >
                        <menu.icon
                          className="mr-3 h-6 w-6 flex-shrink-0 text-indigo-300"
                          aria-hidden="true"
                        />
                        {menu.name}
                        {menu.submenu && (
                          <ChevronDownIcon
                            className={` ${submenuOpen[menu.name.toLowerCase()]
                              ? "-rotate-0"
                              : "-rotate-90"
                              } mr-3 h-6 w-6 flex-shrink-0 text-indigo-150 duration-150`}
                            aria-hidden="true"
                          />
                        )}
                      </Link>
                      {menu.submenu && submenuOpen[menu.name.toLowerCase()] && (
                        <div>
                          {submenuOpen &&
                            menu.submenuItems.map((submenuItem, index) => (
                              <Link
                                to={submenuItem.href}
                                className={classNames(
                                  window.location.pathname === submenuItem.href
                                    ? "bg-indigo-800 text-white"
                                    : "text-indigo-100 hover:bg-indigo-600",
                                  "group flex items-center  px-2 py-2  font-medium rounded-md"
                                )}
                                key={index}
                              >
                                <submenuItem.icon
                                  className="ml-5 mr-3 h-5 w-6 flex-shrink-0 text-indigo-300"
                                  aria-hidden="true"
                                />
                                {submenuItem.name}
                              </Link>
                            ))}
                        </div>
                      )}
                    </>
                  )
                )}
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-1 overflow-hidden flex-col md:pl-64 ">
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1">
                {/* CAN PUT CONTENT HERE INSTEAD OF SEARCH BAR */}
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* Notification Dropdown */}
                <Menu as="div" className="relative ml-3">
                  <Link className="cursor-pointer" to="/notifications">
                    <div className="w-7 relative">
                      <div className="cursor-pointer">
                        <BellIcon size={18} />
                      </div>

                      {notify.data?.unread_notifications &&
                        notify.data?.unread_notifications > 0 && (
                          <span className="bg-blue-500 p-2 text-white rounded-full h-4 w-auto absolute top-0 right-0 flex items-center justify-center">
                            {notify.data.unread_notifications}
                          </span>
                        )}
                    </div>
                  </Link>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-auto max-w-2xl origin-top-right rounded-md bg-white p-5 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {/* <Notifications
                          data={notify.data}
                          loading={notify.loading}
                        /> */}
                        {/* //TODO: Notification is here */}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3 ">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center   bg-white text-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <img className="h-8" src={Brandlogo} alt="" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item, key) => (
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              onClick={item.click ? item.onclick : null}
                              to={item.click ? null : item.href}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="overflow-x-hidden ">
            <div className="py-6   overflow-x-hidden">
              <div className="max-w-[91rem]overflow-x-hidden px-4 sm:px-6 md:px-8">
                <div className="w-full">{children}</div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
