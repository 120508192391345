import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import Modal from "../../../components/Modal";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import { toast } from "react-hot-toast";
import { CancledEvaluationsSessionLog_colums } from "../../../components/tables/tableheader";
import moment from "moment-timezone";
import axios from "axios";
import { getHeaders } from "../../../redux/auth/actions";
import Select from "react-select";
import RcCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { getAllLocations } from "../../../redux/location/actions";
import { getAllTherapists } from "../../../redux/therapist/therpist/actions";

const api = process.env.REACT_APP_API_URL;

const initialModalState = {
  id: "",
  state: false,
  patient_id: "",
  department_id: "",
  location_id: "",
  evaluation_id: "",
};

function CancledEvaluationSessionLog() {
  const [modalData, setModalData] = useState(initialModalState);
  const [query, setQuery] = useState(200);
  const dispatch = useDispatch();

  const { list: therapistList } = useSelector((state) => state.Therapist);
  const { list: locationList } = useSelector((state) => state.location);

  const [availableTherapists, setAvailableTherapists] = useState({
    loading: false,
    data: [],
  });

  const [availableDates, setAvailableDates] = useState({
    loading: false,
    data: [],
  });

  const [timeSlots, setTimeSlots] = useState({
    loading: false,
    data: [],
  });

  const [CancledEvaluationSessionLog, setCancledEvaluationSessionLog] =
    useState({
      loading: false,
      data: [],
    });

  const getAllCancledEvaluationSessionLogs = async (limit) => {
    const headers = await dispatch(getHeaders());
    setCancledEvaluationSessionLog((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(
        `${api}/api/therapist/appointment/cancelled-evaluation?limit=${limit}`,
        { headers }
      );
      if (res.status === 200) {
        setCancledEvaluationSessionLog((prev) => ({
          ...prev,
          data: res.data.data,
          loading: false,
        }));
      }
    } catch (error) {
      toast.error(error.message);
      setCancledEvaluationSessionLog((prev) => ({ ...prev, loading: false }));
    }
  };

  const getTherapists = async (departmentId) => {
    setAvailableTherapists((prev) => ({ ...prev, loading: true }));
    try {
      const filteredTherapists = therapistList.filter((therapist) =>
        therapist.Therapist_department.some(
          (dept) => dept.department_id === Number(departmentId)
        )
      );
      setAvailableTherapists({
        loading: false,
        data: filteredTherapists.map((therapist) => ({
          value: therapist.id,
          label: `${therapist.first_name} ${therapist.last_name}`,
        })),
      });
    } catch (error) {
      toast.error("Error fetching therapists");
      setAvailableTherapists({
        loading: false,
        data: [],
      });
    }
  };

  const getAllDates = async (therapistId) => {
    const headers = await dispatch(getHeaders());
    setAvailableDates((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(
        `${api}/api/evaluation-appointment/search/dates/${therapistId}`,
        { headers }
      );
      setAvailableDates({
        loading: false,
        data: res.data.data || [],
      });
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching dates");
      setAvailableDates({
        loading: false,
        data: [],
      });
    }
  };

  const getTimeSlots = async (therapistId, date) => {
    const headers = await dispatch(getHeaders());
    setTimeSlots((prev) => ({ ...prev, loading: true }));
    try {
      const response = await axios.post(
        `${api}/api/evaluation-appointment/search/date/time-slots`,
        {
          therapist_id: Number(therapistId),
          date: moment.utc(date).format("YYYY-MM-DD"),
          time_split_duration: 60,
        },
        { headers }
      );
      setTimeSlots({
        loading: false,
        data: response.data.data || [],
      });
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching time slots");
      setTimeSlots({
        loading: false,
        data: [],
      });
    }
  };

  useEffect(() => {
    dispatch(getAllLocations());
    dispatch(getAllTherapists());
  }, [dispatch]);

  const adjModal = () => {
    const { state, id, patient_id, department_id, location_id, evaluation_id } =
      modalData;

    // Get the department data from the modalData
    const departmentData = CancledEvaluationSessionLog.data.find(
      (log) => log.id === modalData.id
    )?.DepartmentMaster;

    const initialValues = {
      therapist_id: "",
      form_date: "",
      time_slot: "",
      department_id: department_id || "",
      location_id: location_id || "",
      evaluation_id: evaluation_id || "",
    };

    return (
      <Modal
        size="max-w-md"
        open={state}
        title="Adjust Appointment"
        setOpen={() =>
          setModalData((prev) => ({ ...prev, state: false, id: "" }))
        }
      >
        <div className="mt-2 w-full flex items-center justify-center">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
              const headers = await dispatch(getHeaders());
              try {
                const [start_time, end_time] = values.time_slot.split(" - ");

                const res = await axios.put(
                  `${api}/api/therapist/appointment/evaluation-adjustment/${modalData.id}`,
                  {
                    patient_id: modalData.patient_id,
                    date: moment.utc(values.form_date).format("YYYY-MM-DD"),
                    therapist_id: values.therapist_id,
                    start_time: start_time,
                    end_time: end_time,
                  },
                  { headers }
                );

                if (res.status === 200) {
                  toast.success("Successfully adjusted appointment");
                  getAllCancledEvaluationSessionLogs(query);
                  setModalData((prev) => ({ ...prev, state: false, id: "" }));
                }
              } catch (error) {
                toast.error(
                  error.response?.data?.message || "Error adjusting appointment"
                );
              }
            }}
          >
            {(formik) => (
              <form
                className="w-[90%] space-y-4"
                onSubmit={formik.handleSubmit}
              >
                {/* Department Selection */}
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-700">
                    Select Department
                  </label>
                  <select
                    id="department_id"
                    name="department_id"
                    value={formik.values.department_id}
                    onChange={(e) => {
                      formik.setFieldValue("department_id", e.target.value);
                      getTherapists(e.target.value);
                      formik.setFieldValue("therapist_id", "");
                      formik.setFieldValue("form_date", "");
                      formik.setFieldValue("time_slot", "");
                    }}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="">Select Department</option>
                    {departmentData && (
                      <option key={departmentData.id} value={departmentData.id}>
                        {departmentData.name}
                      </option>
                    )}
                  </select>
                </div>

                {/* Therapist Selection */}
                {formik.values.department_id && (
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Select Therapist
                    </label>
                    <Select
                      options={availableTherapists.data}
                      value={availableTherapists.data.find(
                        (t) => t.value === formik.values.therapist_id
                      )}
                      onChange={(selectedOption) => {
                        formik.setFieldValue(
                          "therapist_id",
                          selectedOption.value
                        );
                        getAllDates(selectedOption.value);
                        formik.setFieldValue("form_date", "");
                        formik.setFieldValue("time_slot", "");
                      }}
                      isLoading={availableTherapists.loading}
                      isDisabled={availableTherapists.loading}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                )}

                {/* Date Selection */}
                {formik.values.therapist_id && (
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Select Date
                    </label>
                    <RcCalendar
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      minDetail="month"
                      tileDisabled={({ date }) => {
                        return !availableDates.data.includes(
                          moment.utc(date).format("YYYY-MM-DD")
                        );
                      }}
                      value={formik.values.form_date}
                      onChange={(value) => {
                        formik.setFieldValue(
                          "form_date",
                          moment.utc(value).format("YYYY-MM-DD")
                        );
                        getTimeSlots(formik.values.therapist_id, value);
                        formik.setFieldValue("time_slot", "");
                      }}
                    />
                  </div>
                )}

                {/* Time Slot Selection */}
                {formik.values.form_date && (
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Select Time Slot
                    </label>
                    {timeSlots.loading ? (
                      <div className="flex justify-center items-center">
                        <FallingLinesLoader />
                      </div>
                    ) : (
                      <select
                        id="time_slot"
                        name="time_slot"
                        value={formik.values.time_slot}
                        onChange={formik.handleChange}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      >
                        <option value="">Select Time Slot</option>
                        {timeSlots.data.map((slot, index) => (
                          <option
                            key={index}
                            value={`${slot.start_time} - ${slot.end_time}`}
                          >
                            {moment
                              .utc(slot.start_time, "HH:mm:ss")
                              .format("hh:mm A")}{" "}
                            -{" "}
                            {moment
                              .utc(slot.end_time, "HH:mm:ss")
                              .format("hh:mm A")}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                )}

                {/* Submit Button */}
                <div className="mt-5">
                  <button
                    type="submit"
                    disabled={formik.isSubmitting || !formik.isValid}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm disabled:bg-gray-400"
                  >
                    {formik.isSubmitting ? "Updating..." : "Update"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    getAllCancledEvaluationSessionLogs(query);
  }, [query]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {adjModal()}
      <div>
        <h1 className="text-xl font-semibold text-gray-900">
          Canceled Evaluation Logs
        </h1>
      </div>
      {CancledEvaluationSessionLog.loading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={CancledEvaluationsSessionLog_colums({ setModalData })}
          data={CancledEvaluationSessionLog.data}
          fetch={async (d) => {
            setQuery(d);
            await getAllCancledEvaluationSessionLogs(d);
          }}
        />
      )}
    </div>
  );
}

export default CancledEvaluationSessionLog;
