import moment from "moment";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { GrCheckmark } from "react-icons/gr";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { capitalise, capitalizeFirstLetter } from "../../utilities/utilities";
import { useState } from "react";
import { Switch } from "@headlessui/react";
import { Togglebtn } from "../toggle switch";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import EditTooptip from "../InlineEdit";
import React from "react";
import { Formik } from "formik";
import axios from "axios";
import { toast } from "react-hot-toast";
import { classNames } from "../../utilities/classname";
import app_api from "../../utilities/api";
export const user_columns = () => [
  {
    Header: "Name",
    accessor: (d) => capitalise(d.firstName) + capitalise(d.lastName),
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Role",
    accessor: (d) => capitalise(d.role.toLowerCase()).replace("_", " "),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to={`/add-user/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
        </>
      );
    },
  },
];
export const CancledAppointmentSessionLog_colums = ({ setModalData }) => [
  {
    Header: "Patient Name",
    accessor: (d) =>
      capitalise(d?.therapist_appointment?.patient?.firstName) +
      capitalise(d?.therapist_appointment?.patient?.lastName),
  },
  {
    Header: "Patient ID",
    accessor: (d) => d?.therapist_appointment?.patient?.patient_id,
  },
  {
    Header: "Department",
    accessor: (d) => d?.department?.name,
  },
  {
    Header: "Date",
    accessor: (d) => moment(d?.date).format("DD/MM/YYYY"),
  },
  {
    Header: "Start time",
    accessor: (d) => moment(d?.start_time).utcOffset(0).format("hh:mm A"),
  },
  {
    Header: "End time",
    accessor: (d) => moment(d?.end_time).utcOffset(0).format("hh:mm A"),
  },
  {
    Header: "Adjusted",
    accessor: (d) =>
      d?.adjusted_date ? (
        moment(d?.adjusted_date).format("DD/MM/YYYY")
      ) : (
        <Link className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
          <button
            onClick={() => {
              setModalData({
                id: d.id,
                state: true,
                date: {
                  name: "",
                },
              });
            }}
            className="text-indigo-900 "
            size="default"
            variant="outlined"
          >
            Adjust<span className="sr-only">, </span>
          </button>
        </Link>
      ),
  },
  // {
  //   Header: "Role",
  //   accessor: (d) => capitalise(d.role.toLowerCase()).replace("_", " "),
  // },
  // {
  //   Header: "Action",
  //   accessor: "action",
  //   Cell: (cell) => {
  //     const cellId = cell.row.original.id;
  //     return (
  //       <>
  //         <Link
  //           to={`/add-user/${cellId}`}
  //           className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
  //         >
  //           <button
  //             className="text-indigo-900 "
  //             size="default"
  //             variant="outlined"
  //           >
  //             Edit<span className="sr-only">, </span>
  //           </button>
  //         </Link>
  //       </>
  //     );
  //   },
  // },
];

export const CancledEvaluationsSessionLog_colums = ({ setModalData }) => [
  {
    Header: "Patient Name",
    accessor: (d) =>
      `${d?.EvaluationAppointments?.Patient?.firstName || ""} ${
        d?.EvaluationAppointments?.Patient?.lastName || ""
      }`.trim(),
  },
  {
    Header: "Patient ID",
    accessor: (d) => d?.EvaluationAppointments?.Patient?.patient_id,
  },
  {
    Header: "Department",
    accessor: (d) => d?.DepartmentMaster?.name,
  },
  {
    Header: "Evaluation",
    accessor: (d) => d?.EvaluationMaster?.name,
  },
  {
    Header: "Date",
    accessor: (d) => moment(d?.date).format("DD/MM/YYYY"),
  },
  {
    Header: "Start Time",
    accessor: (d) => moment(d?.start_time).utcOffset(0).format("hh:mm A"),
  },
  {
    Header: "End Time",
    accessor: (d) => moment(d?.end_time).utcOffset(0).format("hh:mm A"),
  },
  {
    Header: "Adjusted",
    accessor: (d) =>
      d?.adjusted ? (
        moment(d?.date).format("DD/MM/YYYY")
      ) : (
        <button
          onClick={() => {
            setModalData({
              id: d.id,
              state: true,
              date: {
                name: "",
              },
            });
          }}
          className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
        >
          Adjust
        </button>
      ),
  },
];

export const extra_activity_columns = ({ name, goal }) => [
  {
    Header: name ? name : "value",
    accessor: "value",
  },
  {
    Header: "Week Number",
    accessor: "week_number",
  },
  {
    Header: "Department",
    accessor: (d) => d?.DepartmentMaster?.name,
  },
];

export const extra_feedback = () => [
  {
    Header: "goals",
    accessor: (d) => d?.goal_master?.name,
  },
  {
    Header: "Department",
    accessor: (d) => d?.DepartmentMaster?.name,
  },
  {
    Header: "Week Number",
    accessor: "week_number",
  },
  {
    Header: "Feedback",
    accessor: "value",
  },
];

export const CountDown_colums = ({ name }) => [
  {
    Header: "patient Id",
    accessor: (e) => e.enrollment.appointment.patient.patient_id,
    Cell: (cell) => {
      return (
        <Link
          to={`/patient-view/${cell.row.original.id}`}
          className="text-gray-500 text-sm hover:underline"
        >
          {cell && cell.row.original.enrollment.appointment.patient.patient_id}
        </Link>
      );
    },
  },
  {
    Header: "Appointment Id",
    accessor: (e) => e.enrollment.appointment.id,
  },
  {
    Header: "Patient Name",
    accessor: (e) =>
      capitalise(e.enrollment.appointment.patient.firstName) +
      " " +
      capitalise(e.enrollment.appointment.patient.lastName),
  },
  {
    Header: "program name",
    accessor: (e) =>
      e.enrollment.appointment.patient_recommendation.programMaster.name,
  },
  {
    Header: "Start Date",
    accessor: (e) => moment(e?.countdown_start_date).format("DD/MM/YYYY"),
  },
  {
    Header: "End Date",
    accessor: (e) => moment(e?.countdown_end_date).format("DD/MM/YYYY"),
  },
  {
    Header: "countdown Month",
    accessor: (e) => (e?.countdown_month ? e?.countdown_month : "N/A"),
  },
  // {
  //   Header: "OPD status",
  //   accessor: (e) =>
  //     e?.enrollment.session_opd_status
  //       ? e?.enrollment.session_opd_status
  //       : "N/A",
  // },
  {
    Header: "countdown date",
    accessor: (e) => e.countdown_end_date,
    Cell: (cell) => {
      const countdown =
        30 +
        Number(
          moment(cell.row.original.countdown_start_date).diff(
            moment().toISOString(),
            "days"
          )
        );
      const today = moment().format("YYYY-MM-DD");
      const countdown_Date = moment(
        cell.row.original.countdown_start_date
      ).format("YYYY-MM-DD");

      if (countdown_Date) {
        if (today === countdown_Date || today > countdown_Date) {
          return countdown;
        } else {
          return 30;
        }
      } else {
        return "N/A";
      }
    },
  },
];

export const PlanDashboardLog_colums = ({
  setModal,
  onDeleteOpen,
  setConfirmationModal,
  setrConfirmationModal,
}) => [
  {
    Header: "Child name",
    accessor: (d) => (d.patient_name ? capitalise(d.patient_name) : "N/A"),
  },
  {
    Header: "center name",
    accessor: (d) => (d.center_name ? capitalise(d.center_name) : "N/A"),
  },
  {
    Header: "Enrolment Date",
    accessor: (d) =>
      d.enrollment_date
        ? moment(d.enrollment_date).format("DD/MM/YYYY")
        : "N/A",
  },
  {
    Header: "Program & Month",
    accessor: (d) =>
      d.patient_recommendation_programs
        ? d.patient_recommendation_programs
        : "N/A",
  },
  {
    Header: "Department & Date",
    accessor: (d) =>
      d?.department_wise_details?.length > 0
        ? d?.department_wise_details
            ?.map(
              (e) =>
                e?.department?.name + "-" + moment(e?.date).format("DD/MM/YYYY")
            )
            ?.join(", ")
        : "N/A",
  },
  {
    Header: "Plan Submission deadline date",
    accessor: (d) =>
      d.plan_subission_dealine
        ? moment(d.plan_subission_dealine).format("DD/MM/YYYY")
        : "N/A",
  },
  {
    Header: "status",
    accessor: (d) => (d.status ? capitalise(d.status) : "N/A"),
  },
  {
    Header: "Program number",
    accessor: (d) => d.program_number,
  },
  {
    Header: "Month 1",
    accessor: "mon1",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original?.plan_builder_done === false ? (
            <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
              <Link
                className="text-cyan-900"
                size="default"
                variant="outlined"
                to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/1`}
              >
                Add Plan<span className="sr-only">, </span>
              </Link>
            </span>
          ) : (
            <div className="flex items-center justify-center gap-2">
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/therapist-enrolment/plan-view/${cell.row.original.enrollment_id}/1`}
                >
                  View Plan<span className="sr-only">, </span>
                </Link>
              </span>
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/1`}
                >
                  Edit<span className="sr-only">, </span>
                </Link>
              </span>
            </div>
          )}
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              onClick={() => {
                setrConfirmationModal({
                  status: true,
                  id: cell.row.original.enrollment_id,
                  body: {
                    enrollment_id: cell.row.original.enrollment_id,
                    month: 1,
                  },
                });
              }}
              size="default"
              variant="outlined"
            >
              Requisite
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
  {
    Header: "Month 2",
    accessor: "mon2",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original?.plan_builder_done === false ? (
            <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
              <Link
                className="text-cyan-900"
                size="default"
                variant="outlined"
                to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/2`}
              >
                Add Plan<span className="sr-only">, </span>
              </Link>
            </span>
          ) : (
            <div className="flex items-center justify-center gap-2">
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/therapist-enrolment/plan-view/${cell.row.original.enrollment_id}/2`}
                >
                  View Plan<span className="sr-only">, </span>
                </Link>
              </span>
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/2`}
                >
                  Edit<span className="sr-only">, </span>
                </Link>
              </span>
            </div>
          )}

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              onClick={() => {
                setConfirmationModal({
                  status: true,
                  id: cell.row.original.enrollment_id,
                  body: {
                    enrollment_id: cell.row.original.enrollment_id,
                    month: 2,
                  },
                });
              }}
              size="default"
              variant="outlined"
            >
              Reuse
              <span className="sr-only">, </span>
            </button>
          </span>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              onClick={() => {
                setrConfirmationModal({
                  status: true,
                  id: cell.row.original.enrollment_id,
                  body: {
                    enrollment_id: cell.row.original.enrollment_id,
                    month: 2,
                  },
                });
              }}
              size="default"
              variant="outlined"
            >
              Requisite
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
  {
    Header: "Month 3",
    accessor: "mon3",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original?.plan_builder_done === false ? (
            <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
              <Link
                className="text-cyan-900"
                size="default"
                variant="outlined"
                to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/3`}
              >
                Add Plan<span className="sr-only">, </span>
              </Link>
            </span>
          ) : (
            <div className="flex items-center justify-center gap-2">
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/therapist-enrolment/plan-view/${cell.row.original.enrollment_id}/3`}
                >
                  View Plan<span className="sr-only">, </span>
                </Link>
              </span>
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/3`}
                >
                  Edit<span className="sr-only">, </span>
                </Link>
              </span>
            </div>
          )}

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              onClick={() => {
                setConfirmationModal({
                  status: true,
                  id: cell.row.original.enrollment_id,
                  body: {
                    enrollment_id: cell.row.original.enrollment_id,
                    month: 3,
                  },
                });
              }}
              size="default"
              variant="outlined"
            >
              Reuse
              <span className="sr-only">, </span>
            </button>
          </span>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              onClick={() => {
                setrConfirmationModal({
                  status: true,
                  id: cell.row.original.enrollment_id,
                  body: {
                    enrollment_id: cell.row.original.enrollment_id,
                    month: 3,
                  },
                });
              }}
              size="default"
              variant="outlined"
            >
              Requisite
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const EvMaster_colums = ({ setModal, onDeleteOpen }) => [
  {
    Header: "Name",
    accessor: (d) => capitalise(d.name),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full">
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
              onClick={() => {
                setModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "edit",
                  edit_id: cell.row.original.id,
                }));
              }}
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const EvDepartment_colums = ({ setModal, onDeleteOpen }) => [
  {
    Header: "Evaluation Name",
    accessor: (d) => capitalise(d?.EvaluationMaster?.name),
  },
  {
    Header: "department",
    accessor: (d) => capitalise(d?.DepartmentMaster?.name),
  },
  {
    Header: "location",
    accessor: (d) => capitalise(d.Location?.name),
  },
  {
    Header: "cost",
    accessor: "cost",
  },
  {
    Header: "time",
    accessor: "time",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full">
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
              onClick={() => {
                setModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "edit",
                  edit_id: cell.row.original.id,
                }));
              }}
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const doctor_columns = ({ onDeleteOpen }) => [
  {
    Header: "First Name",
    accessor: (d) => `Dr. ${capitalise(d.first_name)}`,
  },
  {
    Header: "Last Name",
    accessor: (d) => capitalise(d.last_name),
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Phone No",
    accessor: "phone_number",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to={`/show-availability/${cellId}`}
            className="inline-flex px-2 text-xs leading-5 text-green-600 mr-2 bg-green-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Show Availability<span className="sr-only">, </span>
            </button>
          </Link>
          <Link
            to={`${cellId}/schedule`}
            className="inline-flex px-2 text-xs leading-5 text-green-600 bg-green-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Schedule<span className="sr-only">, </span>
            </button>
          </Link>
          <Link
            to={`/add-doctor/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const patient_columns = ({ onDeleteOpen }) => [
  {
    Header: "Patient ID",
    accessor: (e) => (e.patient_id ? e.patient_id : "-"),
  },
  {
    Header: "Patient Name",
    accessor: (d) =>
      capitalise(d.firstName.trim()) + " " + capitalise(d.lastName.trim()),
  },

  {
    Header: "Parent First Name",
    // accessor: (d) => capitalise(d.parentFirstName || "-"),
    accessor: "parent-first-name",
    Cell: (cell) => {
      const fatherFirstName = cell.row.original.fatherFirstName;
      const motherFirstName = cell.row.original.motherFirstName;
      const guardiansFirstName = cell.row.original.guardiansFirstName;
      return (
        <>
          {cell && fatherFirstName ? (
            <p className="text-gray-500 text-sm">
              {capitalise(fatherFirstName)}
            </p>
          ) : motherFirstName ? (
            <p className="text-gray-500 text-sm">
              {capitalise(motherFirstName)}
            </p>
          ) : guardiansFirstName ? (
            <p className="text-gray-500 text-sm">
              {capitalise(guardiansFirstName)}
            </p>
          ) : (
            <p className="text-gray-500 text-sm">-</p>
          )}
        </>
      );
    },
  },
  {
    Header: "Parent Last Name",
    // accessor: (d) => capitalise(d.parentLastName || "-"),
    accessor: "parent-last-name",
    Cell: (cell) => {
      const fatherLastName = cell.row.original.fatherLastName;
      const motherLastName = cell.row.original.motherLastName;
      const guardiansLastName = cell.row.original.guardiansLastName;
      return (
        <>
          {cell && fatherLastName ? (
            <p className="text-gray-500 text-sm">
              {capitalise(fatherLastName)}
            </p>
          ) : motherLastName ? (
            <p className="text-gray-500 text-sm">
              {capitalise(motherLastName)}
            </p>
          ) : guardiansLastName ? (
            <p className="text-gray-500 text-sm">
              {capitalise(guardiansLastName)}
            </p>
          ) : (
            <p className="text-gray-500 text-sm">-</p>
          )}
        </>
      );
    },
  },
  {
    Header: "Phone Number",
    accessor: "phoneNo",
  },
  {
    Header: "country",
    accessor: "country",
  },
  {
    Header: "Address",
    accessor: (d) => capitalise(d.address),
  },
  {
    Header: "Date Of Birth",
    accessor: (d) => moment(d.dob).format("DD-MM-yy"),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to={`/view-patient/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              View<span className="sr-only">, </span>
            </button>
          </Link>
          <Link
            to={`/add-patient/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const schedule_columns = ({ onDeleteOpen }) => [
  {
    Header: "Name",
    accessor: (d) => capitalise(d.name),
  },
  {
    Header: "Start Date",
    accessor: (d) => moment(d.start_date).format("DD-MM-YYYY"),
  },
  {
    Header: "End Date",
    accessor: (d) => moment(d.end_date).format("DD-MM-YYYY"),
  },
  {
    Header: "Location",
    accessor: (d) => (d.location_id ? d.location.name : " - "),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to={`${cellId}/availability`}
            className="inline-flex px-2 text-xs leading-5 text-green-600 bg-green-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Availability
            </button>
          </Link>
          <Link
            to={`add/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const Therapist_schedule_columns = ({ onDeleteOpen }) => [
  {
    Header: "Name",
    accessor: (d) => capitalise(d.name),
  },
  {
    Header: "Start Date",
    accessor: (d) => moment(d.start_date).format("DD-MM-YYYY"),
  },
  {
    Header: "End Date",
    accessor: (d) => moment(d.end_date).format("DD-MM-YYYY"),
  },
  {
    Header: "Location",
    accessor: (d) => (d.location_id ? d.location.name : " - "),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to={`${cellId}/therapist-availability`}
            className="inline-flex px-2 text-xs leading-5 text-green-600 bg-green-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Availability
            </button>
          </Link>
          <Link
            to={`add/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const location_columns = ({ onDeleteOpen }) => [
  {
    Header: "Name",
    accessor: (d) => capitalise(d.name),
  },
  {
    Header: "Postal Code",
    accessor: "location_code",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-location/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const goallogs_columns = ({ setModal }) => [
  {
    Header: "Patient Name",
    accessor: (p) =>
      p?.patient?.patient?.firstName + " " + p?.patient?.patient?.lastName,
  },
  {
    Header: "Form Type",
    accessor: (p) => p?.patient?.form_type,
  },
  {
    Header: "Form Date",
    accessor: (p) => moment(p?.patient?.form_date).format("DD/MM/YYYY"),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            onClick={() =>
              setModal((prev) => ({ id: cell.row.original.id, state: true }))
            }
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            i
          </Link>
        </>
      );
    },
  },
];
export const all_comments_columns = () => [
  {
    Header: "patient ",
    accessor: (d) =>
      capitalise(
        d.Enrollment.appointment.patient.firstName +
          " " +
          d.Enrollment.appointment.patient.lastName
      ),
  },
  {
    Header: "comment",
    accessor: (d) => capitalise(d.comment),
  },
  {
    Header: "Added By",
    accessor: (d) => capitalise(d?.commented_by_user?.firstName),
  },
  {
    Header: "status",
    accessor: (d) => capitalise(d?.status),
  },
];

export const Patient_Report_columns = () => [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "No of Children",
    accessor: "no_of_children",
  },
  {
    Header: "Potential",
    accessor: "potential",
  },
  {
    Header: "UK",
    accessor: "no_of_child_uk",
  },
  {
    Header: "Dropout",
    accessor: "dropout",
  },
];
export const New_Patient_Report_columns = () => [
  {
    Header: "name",
    accessor: "name",
  },
  {
    Header: "Enrollments",
    accessor: "enrollments",
  },
  {
    Header: "session",
    accessor: "session",
  },
];
export const collection_report_columns = () => [
  {
    Header: "date",
    accessor: (d) => moment(d.date).format("DD/MM/YYYY"),
  },
  {
    Header: "therapist name",
    accessor: "therapistName",
  },
  {
    Header: "child Name",
    accessor: "childName",
  },
  {
    Header: "total Amount",
    accessor: "totalAmountRs",
  },
  {
    Header: "department name",
    accessor: "deptName",
  },
  {
    Header: "month",
    accessor: (e) =>
      e?.month?.value ? e?.month?.name?.[0] + " - " + e?.month?.value : "N/A",
  },
  {
    Header: "balance",
    accessor: "balance",
  },

  {
    Header: "rate",
    accessor: "rate",
  },

  {
    Header: "total cost",
    accessor: "total_cost",
  },
];
export const Report_columns = () => [
  {
    Header: "Department",
    accessor: "department",
  },
  {
    Header: "Specialists Name",
    accessor: "Specialists_name",
  },
  {
    Header: "Timing",
    accessor: "timing",
  },
  {
    Header: "per session",
    accessor: "session",
  },
  {
    Header: "Online Sessions",

    columns: [
      {
        Header: "Total",
        accessor: (d) => capitalise(d.firstName) + capitalise(d.lastName),
      },
      {
        Header: "Enable	",
        accessor: "email",
        className: "border-l border-r border-t",
      },
      {
        Header: "Counselling / NHDP	",
        accessor: "conuser",
        className: "border-l border-r border-t",
      },
    ],
  },

  {
    Header: "Physical (Sessions Count)",
    accessor: "physical",
  },
  {
    Header: "Eval (Count)",
    accessor: "eval",
  },
  {
    Header: "total",
    accessor: "eval_total",
  },
];

export const Patient_And_Session_Report_columns = (my_headers) => [
  ...my_headers,
];

export const patient_session_total = (my_headers) => [
  { Header: "date", accessor: "date" },
  {
    Header: "Patient",
    accessor: "patient",
  },
  {
    Header: "Session",
    accessor: "session",
  },
];

// All Apointments of a perticuler Petient column
export const patient_apointment_columns = () => [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Time",
    accessor: "time",
  },
  {
    Header: "Doctor Name",
    accessor: (e) => ` Dr. {e.doctor}`,
  },
  {
    Header: "Phone Number",
    accessor: "phone",
  },
  {
    Header: "Address",
    accessor: "location",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      // const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to="/add-patient"
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button size="default" variant="outlined">
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

// Petient All Invoices
export const patient_invoice_columns = () => [
  {
    Header: "Issue Date",
    accessor: "date",
  },
  {
    Header: "Doctor Name",
    accessor: (e) => `Dr. ${e.doctor}`,
  },
  {
    Header: "Phone Number",
    accessor: "phone",
  },
  {
    Header: "Total Balance",
    accessor: "total",
  },
  {
    Header: "Balance",
    accessor: "balance",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to="/add-invoice"
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button size="default" variant="outlined">
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
// Prescription For Patient
export const prescription_columns = () => [
  {
    Header: "Patient Name",
    accessor: "patientName",
  },
  {
    Header: "Doctor Name",
    id: "doctorName",
    Cell: (cell) => {
      return (
        <>
          <p className="text-gray-500 text-sm">{`Dr. ${cell.row.original.doctorName}`}</p>
        </>
      );
    },
  },
  {
    Header: "Patient Age",
    accessor: "doctor",
  },
  {
    Header: "Refer By",
    accessor: "referBy",
  },
  {
    Header: "Hospital",
    accessor: "hospital",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      // const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to="/add-patient"
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button size="default" variant="outlined">
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const medicine_columns = ({ removeMedicine, handleDrawer }) => [
  {
    Header: "Medicine Name",
    accessor: "medicine_name",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const dataobj = cell.row.original;
      const cellData = cell.row.original.id;
      return (
        <>
          <span
            onClick={() => handleDrawer("edit", cellData, dataobj)}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                removeMedicine(cellData);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const role_columns = ({ onDeleteOpen }) => [
  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Status",
    accessor: "role_status",
    Cell: (cell) => {
      const cellStatus = cell.row.original.role_status;
      const [roleStatusChange, setRoleStatusChange] = useState(cellStatus);
      function roleStatus(...status) {
        return status.filter(Boolean).join(" ");
      }
      return (
        <>
          <Switch
            checked={roleStatusChange}
            onChange={setRoleStatusChange}
            className={roleStatus(
              roleStatusChange ? "bg-indigo-600" : "bg-gray-200",
              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={roleStatus(
                roleStatusChange ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
        </>
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to={`/add-role/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const announcement_columns = () => [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      // const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to="/add-patient"
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button size="default" variant="outlined">
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const goal_columns = () => [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      // const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to="/add-patient"
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button size="default" variant="outlined">
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const recommendation_columns = () => [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      // const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to="/add-patient"
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button size="default" variant="outlined">
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const userGroup_columns = () => [
  {
    Header: "Group Name",
    accessor: "ug_name",
  },
  {
    Header: "Users",
    accessor: "ug_users",
    Cell: (cell) => {
      // console.log(cell.row.original)
      // const userGroupId = cell.row.original.ug_id
      const userGroupUsers = cell.row.original.ug_users;

      // const cellId = cell.row.original.id;
      return (
        <div className="flex">
          {userGroupUsers.map((user) => (
            <div className="flex ml-1 mr-1">
              <Link
                to="/"
                className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
              >
                <button
                  className="text-indigo-900 "
                  size="default"
                  variant="outlined"
                >
                  {user.user_id + " - " + user.user_email}
                  <span className="sr-only">, </span>
                </button>
              </Link>
            </div>
          ))}
        </div>
      );
    },
  },
];

/**** All payments columns *******/
export const payment_columns = ({
  sendPaymentLinkConfirmation,
  setShowModal,
  markPaid,
  sendInvoiceLink,
  setModal,
}) => [
  {
    Header: "Doctor Name",
    accessor: (e) =>
      `Dr. ${e.appointment.doctor.first_name} ${e.appointment.doctor.last_name}`,
  },
  {
    Header: "Patient Name",
    accessor: (e) =>
      `${e.appointment.patient.firstName} ${e.appointment.patient.lastName}`,
  },
  {
    Header: "Payment ID",
    accessor: (e) => (e.payment_id ? e.payment_id : "N/A"),
  },
  {
    Header: "Status",
    accessor: "payment-status",
    Cell: (cell) => {
      return (
        <>
          {cell && cell.row.original.success ? (
            <GrCheckmark className="text-green-700 " />
          ) : (
            <RxCross2 className="text-red-700" />
          )}
        </>
      );
    },
  },
  {
    Header: "Payment Date",
    id: "payment_date",
    accessor: (d) => moment(d.createdAt).format("DD-MM-YYYY"),
  },
  {
    Header: "Appointment Date",
    id: "Appointment_date",
    accessor: (d) => moment(d.appointment.date).format("DD-MM-YYYY"),
  },
  {
    Header: "Amount",
    id: "amount",
    accessor: (d) => `Rs. ${d.amount}`,
  },
  {
    Header: "Start Time",
    accessor: (t) => t.appointment.start_time,
  },
  {
    Header: "End Time",
    accessor: (t) => t.appointment.end_time,
  },
  {
    Header: "Type",
    accessor: (t) => <p>{t.type === "PENDING" ? "BALANCE PAYMENT" : t.type}</p>,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const dis = (cellId) => {
        if (cell.row.original.pdf) {
          return true;
        } else {
          sendPaymentLinkConfirmation(cellId);
        }
      };

      const cellId = cell.row.original.id;
      return (
        <>
          <a
            href={`${process.env.REACT_APP_API_URL}/uploads/${
              cell && cell.row.original.pdf
            }`}
            className={
              cell.row.original.pdf
                ? "inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
                : "inline-flex px-2 text-xs leading-5 text-white bg-gray-300 rounded-full cursor-default"
            }
            target="_blank"
            rel="noreferrer"
          >
            <button
              disabled={!cell.row.original.pdf}
              className={
                cell.row.original.pdf ? "text-indigo-900" : "text-gray-600"
              }
              size="default"
              variant="outlined"
            >
              Invoice<span className="sr-only">, </span>
            </button>
          </a>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-green-600 bg-green-100 rounded-full">
            <button
              disabled={cell.row.original.pdf}
              onClick={() => {
                // dis(cellId);
                setModal((prev) => ({
                  ...prev,
                  state: true,
                  edit_id: cell.row.original.appointment.patient.phoneNo,
                  index: cellId,
                  type: "payment",
                  title: "Send Payment  Link",
                }));
              }}
              id="send-link"
              size="default"
              variant="outlined"
            >
              Send Link
              <span className="sr-only">, </span>
            </button>
          </span>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-blue-100 rounded-full">
            <button
              className="disabled:cursor-not-allowed"
              disabled={cell.row.original.success}
              onClick={() => {
                setShowModal(true);
                markPaid(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Mark Paid
              <span className="sr-only">, </span>
            </button>
          </span>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-blue-100 rounded-full">
            <button
              className="disabled:cursor-not-allowed"
              disabled={!cell.row.original.success}
              onClick={() => {
                // sendInvoiceLink(cellId);
                setModal((prev) => ({
                  ...prev,
                  state: true,
                  edit_id: cell.row.original.appointment.patient.phoneNo,
                  index: cellId,
                  type: "invoice",
                  title: "Send Invoice  Link",
                }));
              }}
              size="default"
              variant="outlined"
            >
              Invoice Link
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const Therapist_payment_columns = ({
  sendPaymentLink,
  setModalData,
  genInvoice,
  setConfirmationModal,
  sendInvoiceSms,
}) => [
  // {
  //   Header: "Therapist Name",
  //   accessor: (e) =>
  //     `Dr. ${e?.EvaluationAppointments?.TherapistMaster?.first_name} ${e?.EvaluationAppointments?.TherapistMaster?.last_name}`,
  // },
  {
    Header: "Patient Name",
    accessor: (e) =>
      `${e?.EvaluationAppointments?.Patient?.firstName} ${e?.EvaluationAppointments?.Patient?.lastName}`,
  },
  {
    Header: "Payment ID",
    accessor: (e) => (e.payment_id ? e.payment_id : "N/A"),
  },
  {
    Header: "Status",
    accessor: "payment-status",
    Cell: (cell) => {
      return (
        <>
          {cell && cell?.row?.original?.success ? (
            <GrCheckmark className="text-green-700 " />
          ) : (
            <RxCross2 className="text-red-700" />
          )}
        </>
      );
    },
  },
  {
    Header: "Payment Date",
    id: "payment_date",
    accessor: (d) => moment(d?.payment_date).format("DD-MM-YYYY"),
  },
  // {
  //   Header: "Appointment Date",
  //   id: "Appointment_date",
  //   accessor: (d) => moment(d?.appointment?.date).format("DD-MM-YYYY"),
  // },
  {
    Header: "Total Amount",
    id: "amount",
    accessor: (d) => `Rs. ${d?.EvaluationAppointments?.total_amount}`,
  },
  {
    Header: "Pending Amount",
    accessor: (d) => (d?.amount ? `Rs. ${d?.amount}` : "N/A"),
  },
  // {
  //   Header: "Start Time",
  //   accessor: (t) => t?.appointment?.start_time,
  // },
  // {
  //   Header: "End Time",
  //   accessor: (t) => t.appointment.end_time,
  // },
  {
    Header: "Type",
    accessor: (t) => t?.payment_type,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          {cell.row.original.pdf && (
            <a
              href={`${process.env.REACT_APP_API_URL}/uploads/${
                cell && cell.row.original.pdf
              }`}
              className={
                cell.row.original.pdf
                  ? "inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
                  : "inline-flex px-2 text-xs leading-5 text-white bg-gray-300 rounded-full cursor-default"
              }
              target="_blank"
              rel="noreferrer"
            >
              <button
                disabled={!cell.row.original.pdf}
                className={
                  cell.row.original.pdf ? "text-indigo-900" : "text-gray-600"
                }
                size="default"
                variant="outlined"
              >
                Invoice<span className="sr-only">, </span>
              </button>
            </a>
          )}

          {!cell.row.original.pdf && (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-green-600 bg-green-100 rounded-full">
              <button
                disabled={cell.row.original.pdf}
                onClick={() => {
                  setConfirmationModal({
                    id: cellId,
                    status: true,
                    title: "Are you sure?",
                    description: "You want to send payment link?",
                    confirmationButtonText: "send link",
                    func: sendPaymentLink,
                  });
                }}
                id="send-link"
                size="default"
                variant="outlined"
              >
                Send Link
                <span className="sr-only">, </span>
              </button>
            </span>
          )}

          {!cell.row.original.success && (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-blue-100 rounded-full">
              <button
                className="disabled:cursor-not-allowed"
                disabled={cell.row.original.success}
                onClick={() => {
                  setModalData({
                    id: cellId,
                    state: true,
                  });
                }}
                size="default"
                variant="outlined"
              >
                Mark Paid
                <span className="sr-only">, </span>
              </button>
            </span>
          )}

          {cell.row.original.pdf && (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-blue-100 rounded-full">
              <button
                className="disabled:cursor-not-allowed"
                onClick={() => {
                  setConfirmationModal({
                    id: cellId,
                    status: true,
                    title: "Are you sure?",
                    description: "You want to send Invoice SMS?",
                    confirmationButtonText: "Send SMS",
                    func: sendInvoiceSms,
                  });
                }}
                size="default"
                variant="outlined"
              >
                invoice SMS
                <span className="sr-only">, </span>
              </button>
            </span>
          )}
        </>
      );
    },
  },
];
export const therapist_payment_columns = ({
  setShowModal,
  markPaid,
  setSmsModal,
}) => [
  {
    Header: "Patient Name",
    accessor: (e) =>
      `${capitalise(e.enrollment.appointment.patient.firstName)} ${capitalise(
        e.enrollment.appointment.patient.lastName
      )}`,
    Cell: ({ cell: { value }, row: { original } }) => {
      const isRed =
        original.installment === "FIRST" &&
        original.is_success === false &&
        new Date(original.createdAt) <=
          new Date(Date.now() - 24 * 60 * 60 * 1000);
      return <div className={isRed ? "text-red-500" : ""}>{value}</div>;
    },
  },
  {
    Header: "Location",
    accessor: (e) =>
      `${capitalise(e?.enrollment?.appointment?.patient?.location?.name)}`,
  },
  {
    Header: "Payment ID",
    accessor: (e) => (e.payment_id ? e.payment_id : "N/A"),
  },
  {
    Header: "Status",
    accessor: "completed",
    Cell: (cell) => {
      return (
        <>
          {cell && cell.row.original.is_success ? (
            <GrCheckmark className="text-green-700 " />
          ) : (
            <RxCross2 className="text-red-700" />
          )}
        </>
      );
    },
  },

  {
    Header: "Installment Type",
    accessor: (e) => capitalizeFirstLetter(e.installment),
  },
  {
    Header: "Amount",
    accessor: (e) => capitalizeFirstLetter(e?.total_amount),
  },
  {
    Header: "total amount",
    accessor: (e) => capitalizeFirstLetter(e?.enrollment?.total_amount),
  },
  {
    Header: "payment countdown",
    // accessor: "payment_countdown",
    accessor: (cell) => {
      if (cell.payment_countdown_date && cell.installment !== "FIRST") {
        return moment(cell.payment_countdown_date).diff(
          moment().toISOString(),
          "days"
        );
      } else {
        return "-";
      }
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: (cell) => {
      if (cell.row.original.installment === "FIRST") {
        return "-";
      } else {
        return (
          <>
            {cell.row.original.status == "On Time" ? (
              <p
                className={`inline-flex px-2 text-xs leading-5 text-green-600 bg-green-100 rounded-full`}
              >
                {cell.row.original.status}
              </p>
            ) : (
              <p
                className={`inline-flex px-2 text-xs leading-5 text-red-600 bg-red-100 rounded-full`}
              >
                {cell.row.original.status}
              </p>
            )}
          </>
        );
      }
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          {cell.row.original.invoice_pdf && (
            <a
              href={`${process.env.REACT_APP_API_URL}/uploads/${
                cell && cell.row.original.invoice_pdf
              }`}
              className={
                cell.row.original.pdf
                  ? "inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
                  : "inline-flex px-2 text-xs leading-5 text-white bg-gray-300 rounded-full cursor-default"
              }
              target="_blank"
              rel="noreferrer"
            >
              <button
                disabled={!cell.row.original.invoice_pdf}
                className={
                  cell.row.original.pdf ? "text-indigo-900" : "text-gray-600"
                }
                size="default"
                variant="outlined"
              >
                Invoice<span className="sr-only">, </span>
              </button>
            </a>
          )}

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-blue-100 rounded-full">
            <button
              className="disabled:cursor-not-allowed"
              disabled={
                cell.row.original.is_success ||
                cell.row.original.allow_payment === false
              }
              onClick={() => {
                setShowModal(true);
                markPaid(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Mark Paid
              <span className="sr-only">, </span>
            </button>
          </span>

          {cell.row.original.installment === "FIRST" &&
            cell.row.original.is_success === false &&
            new Date(cell.row.original.createdAt) <=
              new Date(Date.now() - 24 * 60 * 60 * 1000) && (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-red-100 rounded-full">
                <button
                  className="disabled:cursor-not-allowed"
                  // onClick={() => {
                  //   setConfirmationModal({
                  //     id: cellId,
                  //     status: true,
                  //     title: "Are you sure?",
                  //     description: "You want release this slot?",
                  //     confirmationButtonText: "Release",
                  //   });
                  // }}
                  size="default"
                  variant="outlined"
                >
                  Release Slot
                  <span className="sr-only">, </span>
                </button>
              </span>
            )}
          {cell.row.original.installment === "SECOND" && (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-blue-100 rounded-full">
              <button
                className="disabled:cursor-not-allowed"
                onClick={() => {
                  setSmsModal((prev) => ({
                    ...prev,
                    state: true,
                    index: cellId,
                    edit_id:
                      cell.row.original.enrollment.appointment.patient.phoneNo,
                  }));
                }}
                size="default"
                variant="outlined"
              >
                Send Payment Link
                <span className="sr-only">, </span>
              </button>
            </span>
          )}
        </>
      );
    },
  },
];
export const red_payment_columns = ({ setShowModal, markPaid }) => [
  {
    Header: "Patient Name",
    accessor: (e) =>
      `${capitalise(e.enrollment.appointment.patient.firstName)} ${capitalise(
        e.enrollment.appointment.patient.lastName
      )}`,
  },
  {
    Header: "Patient ID",

    accessor: (e) => `${e.enrollment.appointment.patient.patient_id} `,
  },
  {
    Header: "amount ",
    accessor: "total_amount",
  },

  {
    Header: "total amount",
    accessor: (e) => e?.enrollment.total_amount,
  },

  // {
  //   Header: "Action",
  //   accessor: "action",
  //   Cell: (cell) => {
  //     // const dis = (cellId) => {
  //     //   if (cell.row.original.pdf) {
  //     //     return true;
  //     //   } else {
  //     //     sendPaymentLinkConfirmation(cellId);
  //     //   }
  //     // };

  //     const cellId = cell.row.original.id;
  //     return (
  //       <>
  //         <a
  //           href={`${process.env.REACT_APP_API_URL}/${
  //             cell && cell.row.original.pdf
  //           }`}
  //           className={
  //             cell.row.original.pdf
  //               ? "inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
  //               : "inline-flex px-2 text-xs leading-5 text-white bg-gray-300 rounded-full cursor-default"
  //           }
  //           target="_blank"
  //           rel="noreferrer"
  //         >
  //           <button
  //             disabled={!cell.row.original.pdf}
  //             className={
  //               cell.row.original.pdf ? "text-indigo-900" : "text-gray-600"
  //             }
  //             size="default"
  //             variant="outlined"
  //           >
  //             Invoice<span className="sr-only">, </span>
  //           </button>
  //         </a>

  //         <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-blue-100 rounded-full">
  //           <button
  //             className="disabled:cursor-not-allowed"
  //             disabled={
  //               cell.row.original.is_success ||
  //               cell.row.original.allow_payment === false
  //             }
  //             onClick={() => {
  //               setShowModal(true);
  //               markPaid(cellId);
  //             }}
  //             size="default"
  //             variant="outlined"
  //           >
  //             Mark Paid
  //             <span className="sr-only">, </span>
  //           </button>
  //         </span>
  //       </>
  //     );
  //   },
  // },
];

export const opdReport_columns = ({
  setShowModal,
  setDisableBtn,
  setMeetLink,
  setId,
}) => [
  // { Header: "ID", accessor: (e) => `${e.id}` },
  {
    Header: "First Name",
    accessor: (d) => capitalise(d.patient.firstName),
    Cell: (cell) => {
      const cellId = cell.row.original.patient.id;
      return (
        <Link
          to={`/view-patient/${cellId}`}
          className="text-gray-500 text-sm
        hover:text-gray-900 hover:underline
        "
        >
          {cell && cell.row.original.patient
            ? cell.row.original.patient.firstName
            : ""}
        </Link>
      );
    },
  },
  {
    Header: "last Name",
    accessor: (d) => capitalise(d.patient.lastName),
  },
  { Header: "Age", accessor: (e) => `${e.patient.age}` },
  {
    Header: "Area",
    // accessor: (e) => (`${e.location.name}`),
    accessor: "area",
    Cell: (cell) => {
      // console.log(cell.row.original.location.name)
      return (
        <p className="text-gray-500 text-sm">
          {cell && cell.row.original.location && cell.row.original.location.name
            ? cell.row.original.location.name
            : ""}
        </p>
      );
    },
  },
  {
    Header: "Consultation time",
    // accessor: (e) =>
    //   `${moment(e.start_time).format("hh:mm A")} - ${moment(e.end_time).format(
    //     "hh:mm A"
    //   )}`,
    accessor: (e) => `${e.start_time} - ${e.end_time}`,
    // accessor: 'consultationTime',
  },
  {
    Header: "OPD Status",
    Cell: (cell) => {
      return (
        <>
          {cell && cell.row.original.Patient_form.length ? (
            cell.row.original.Patient_form[0].finalized ? (
              <AiFillCheckCircle className="text-green-700" />
            ) : (
              <AiFillCloseCircle className="text-red-700" />
            )
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "Type Of Consultation",
    accessor: "is follow up",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm">
          {cell && cell.row.original.isFollowUp ? "f/up" : "new"}
        </p>
      );
    },
  },
  {
    Header: "Reffering Source",
    accessor: "refferingSource",
    Cell: (cell) => {
      // console.log(cell.row.original.location.name)
      return (
        <p className="text-gray-500 text-sm">
          {cell.row.original.patient.referral
            ? cell.row.original.patient.referral.name
            : "N/A"}
        </p>
      );
    },
  },
  {
    Header: "Appointment Date",
    accessor: (e) => `${moment(e.date).format("DD/MM/YYYY")}`,
  },

  /* { Header: "Contact Number", accessor: (e) => `${e.patient.phoneNo}` }, */
  { Header: "status", accessor: "status" },

  {
    Header: "recommendation",
    accessor: "patient_recommendation",
  },
  {
    Header: "Program Number",
    accessor: "program_number",
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      const cellhome = cell.row.original;
      return (
        <>
          {cellhome.type === "OFFLINE" ? (
            "-"
          ) : (
            <Link className="inline-flex px-1 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
              <button
                className="text-indigo-900 "
                size="default"
                variant="outlined"
                onClick={() => {
                  setMeetLink(cell.row.original.meeting_link);
                  setShowModal(true);
                  setId(cellId);
                  console.log(cell.row.original.meeting_link);
                  cellhome.type === "OFFLINE"
                    ? setDisableBtn(true)
                    : setDisableBtn(false);
                }}
              >
                Send Link<span className="sr-only">, </span>
              </button>
            </Link>
          )}
        </>
      );
    },
  },
];

export const TherapistAppointmentLogsColumn = ({
  setConfirmationModal,
  setrConfirmationModal,
}) => [
  {
    Header: "Patient Name",
    accessor: (e) =>
      e.therapist_appointment.patient.firstName +
      " " +
      e.therapist_appointment.patient.lastName,
  },
  {
    Header: "Age",
    accessor: (e) => e.therapist_appointment.patient.age,
  },
  {
    Header: "Appointment Date",
    accessor: (e) => moment(e.date).format("DD-MM-YYYY"),
  },
  {
    Header: "Time",
    accessor: (e) =>
      `${moment(e?.start_time).utcOffset(0).format("hh:mm A")} - ${moment(
        e?.end_time
      )
        .utcOffset(0)
        .format("hh:mm A")}`,
  },
  {
    Header: "Center",
    accessor: (e) => e.Location.name || "N/A",
  },

  {
    Header: "Department",
    accessor: (e) => e.department.name,
  },
  {
    Header: "RECOMMENDATION",
    accessor: (e) =>
      e.therapist_appointment.patient_recommendation.programMaster.name,
  },
  // {
  //   Header: "Program Number",
  //   accessor: (e) => e.program_number || "N/A",
  // },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const patient = cell.row.original.therapist_appointment.patient;
      const therapist_appointment = cell.row.original.therapist_appointment;
      return (
        <div className="flex justify-center space-x-2">
          <Link
            to={`/view-patient/${patient.id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              View OPD<span className="sr-only">, </span>
            </button>
          </Link>
          {therapist_appointment.Enrollment.length && (
            <Link
              to={`/patient-view/${therapist_appointment.Enrollment[0].id}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
            >
              <button
                className="text-indigo-900 "
                size="default"
                variant="outlined"
              >
                Red Flags<span className="sr-only">, </span>
              </button>
            </Link>
          )}
          {/* {therapist_appointment.Enrollment.map((d) => (
            <div className="flex items-center justify-center gap-2">
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/therapist-enrolment/plan-view/${d.id}/${d.countdown_month}`}
                >
                  View Plan<span className="sr-only">, </span>
                </Link>
              </span>
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/patient-view/add-plan/${d.id}/${d.countdown_month}`}
                >
                  Edit<span className="sr-only">, </span>
                </Link>
              </span>
            </div>
          ))} */}
        </div>
      );
    },
  },
  {
    Header: "Month 1",
    accessor: "mon1",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original?.plan_builder_done === false ? (
            <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
              <Link
                className="text-cyan-900"
                size="default"
                variant="outlined"
                to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/1`}
              >
                Add Plan<span className="sr-only">, </span>
              </Link>
            </span>
          ) : (
            <div className="flex items-center justify-center gap-2">
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/therapist-enrolment/plan-view/${cell.row.original.enrollment_id}/1`}
                >
                  View Plan<span className="sr-only">, </span>
                </Link>
              </span>
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/1`}
                >
                  Edit<span className="sr-only">, </span>
                </Link>
              </span>
            </div>
          )}
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              onClick={() => {
                setrConfirmationModal({
                  status: true,
                  id: cell.row.original.enrollment_id,
                  body: {
                    enrollment_id: cell.row.original.enrollment_id,
                    month: 1,
                  },
                });
              }}
              size="default"
              variant="outlined"
            >
              Requisite
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
  {
    Header: "Month 2",
    accessor: "mon2",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original?.plan_builder_done === false ? (
            <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
              <Link
                className="text-cyan-900"
                size="default"
                variant="outlined"
                to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/2`}
              >
                Add Plan<span className="sr-only">, </span>
              </Link>
            </span>
          ) : (
            <div className="flex items-center justify-center gap-2">
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/therapist-enrolment/plan-view/${cell.row.original.enrollment_id}/2`}
                >
                  View Plan<span className="sr-only">, </span>
                </Link>
              </span>
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/2`}
                >
                  Edit<span className="sr-only">, </span>
                </Link>
              </span>
            </div>
          )}

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              onClick={() => {
                setConfirmationModal({
                  status: true,
                  id: cell.row.original.enrollment_id,
                  body: {
                    enrollment_id: cell.row.original.enrollment_id,
                    month: 2,
                  },
                });
              }}
              size="default"
              variant="outlined"
            >
              Reuse
              <span className="sr-only">, </span>
            </button>
          </span>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              onClick={() => {
                setrConfirmationModal({
                  status: true,
                  id: cell.row.original.enrollment_id,
                  body: {
                    enrollment_id: cell.row.original.enrollment_id,
                    month: 2,
                  },
                });
              }}
              size="default"
              variant="outlined"
            >
              Requisite
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
  {
    Header: "Month 3",
    accessor: "mon3",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original?.plan_builder_done === false ? (
            <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
              <Link
                className="text-cyan-900"
                size="default"
                variant="outlined"
                to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/3`}
              >
                Add Plan<span className="sr-only">, </span>
              </Link>
            </span>
          ) : (
            <div className="flex items-center justify-center gap-2">
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/therapist-enrolment/plan-view/${cell.row.original.enrollment_id}/3`}
                >
                  View Plan<span className="sr-only">, </span>
                </Link>
              </span>
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/3`}
                >
                  Edit<span className="sr-only">, </span>
                </Link>
              </span>
            </div>
          )}

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              onClick={() => {
                setConfirmationModal({
                  status: true,
                  id: cell.row.original.enrollment_id,
                  body: {
                    enrollment_id: cell.row.original.enrollment_id,
                    month: 3,
                  },
                });
              }}
              size="default"
              variant="outlined"
            >
              Reuse
              <span className="sr-only">, </span>
            </button>
          </span>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              onClick={() => {
                setrConfirmationModal({
                  status: true,
                  id: cell.row.original.enrollment_id,
                  body: {
                    enrollment_id: cell.row.original.enrollment_id,
                    month: 3,
                  },
                });
              }}
              size="default"
              variant="outlined"
            >
              Requisite
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const opd_Report_columns = ({}) => [
  {
    Header: "Month",
    accessor: "date",
  },
  {
    Header: "New Patients",
    accessor: "new",
  },
  {
    Header: "Follow Up",
    accessor: `followup`,
  },
];

export const consolidate_Report_columns = ({}) => [
  {
    Header: "Sr No.",
    accessor: "id",
  },
  {
    Header: "OPD Date",
    accessor: "opd_date",
  },
  {
    Header: "Name of the Child",
    accessor: `child_name`,
  },
  {
    Header: "Type of consultation",
    accessor: "consultation_type",
  },
  {
    Header: "Residence Area",
    accessor: "residence_area",
  },
  {
    Header: "Gender",
    accessor: "gender",
  },
  {
    Header: "DOB",
    accessor: "date_of_birth",
  },
  {
    Header: "School",
    accessor: "school",
  },
  {
    Header: "Referred By",
    accessor: "referred_by",
  },
  {
    Header: "Contact No.",
    accessor: "contact_no",
  },
  {
    Header: "Email ID",
    accessor: "email_id",
  },
  {
    Header: "Eval/Reval",
    accessor: "eval_reval",
  },
  {
    Header: "Evaluation/Re-Eval Booked/Not Booked",
    accessor: "eval_re_booked",
  },
  {
    Header: "NHDP RECOMM",
    accessor: "nhdp_recom",
  },
  {
    Header: "NHDP Booked /Not",
    accessor: "nhdp_booked",
  },
  {
    Header: "Unknown",
    accessor: "comment",
  },
];

export const CancleopdReport_columns = ({ setModalData, setCommentModal }) => [
  {
    Header: "Name",
    accessor: "patient.firstName",
    Cell: (cell) => {
      const cellId = cell.row.original.patientId;
      return (
        <Link
          to={`/view-patient/${cellId}`}
          className="text-gray-500 text-sm
        hover:text-gray-900 hover:underline capitalize
        "
        >
          {cell && cell.row.original.patient
            ? cell.row.original.patient.firstName +
              " " +
              cell.row.original.patient.lastName
            : ""}
        </Link>
      );
    },
  },
  { Header: "Age", accessor: "patient.age" },
  {
    Header: "Area",
    accessor: "area",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm">
          {cell && cell.row.original.location && cell.row.original.location.name
            ? cell.row.original.location.name
            : ""}
        </p>
      );
    },
  },

  {
    Header: "Appointment Date",
    accessor: (e) => !!e?.date && `${moment(e?.date).format("DD/MM/YYYY")}`,
  },
  {
    Header: "Type Of Consultation",
    accessor: "isFollowUp",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm">
          {cell && cell.row.original.isFollowUp ? "f/up" : "new"}
        </p>
      );
    },
  },
  {
    Header: "Reffering Source",
    accessor: "refferingSource",
    Cell: (cell) => {
      return <p className="text-gray-500 text-sm">-</p>;
    },
  },
  { Header: "Contact Number", accessor: "patient.phoneNo" },
  { Header: "status", accessor: "status" },
  {
    Header: "Consultation time",
    accessor: (e) => `${e?.start_time} - ${e?.end_time}`,
  },
  {
    Header: "Adjusted On",
    accessor: (e) =>
      e?.adjusted_on ? `${moment(e?.adjusted_on).format("DD-MM-YYYY")} ` : "-",
  },
  {
    Header: "adjustment",
    accessor: "adjustment",
    Cell: (cell) => {
      return cell.row.original.adjustment_status ? (
        <p>{moment(cell.row.original.adjustment_date).format("DD-MM-YYYY")}</p>
      ) : (
        <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
          <button
            onClick={() => {
              setModalData({
                id: cell.row.original.id,
                state: true,
                date: {
                  name: "",
                },
              });
            }}
            size="default"
            variant="outlined"
          >
            Adjust
            <span className="sr-only">, </span>
          </button>
        </span>
      );
    },
  },
  {
    Header: "Comment",
    accessor: "Comment",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm">
          {cell && cell.row.original.cancel_comment ? (
            <p>{cell.row.original.cancel_comment}</p>
          ) : (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-black bg-indigo-100 rounded-full">
              <button
                onClick={() =>
                  setCommentModal((prev) => ({
                    ...prev,
                    state: true,
                    id: cell.row.original.id,
                    data: {
                      cancel_comment: "",
                    },
                  }))
                }
                size="default"
                variant="outlined"
              >
                Add Comment
                <span className="sr-only">, </span>
              </button>
            </span>
          )}
        </p>
      );
    },
  },
];

export const Referrals_columns = ({ onDeleteOpen }) => [
  { Header: "Name", accessor: "name" },
  { Header: "Type", accessor: "type" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const ids = cell?.row?.original?.ids; 
      const firstId = ids && ids.length > 0 ? ids[0] : null; 

      return (
        <>
          <Link
            to={`/add-referral/${firstId}`} 
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                if (firstId) {
                  onDeleteOpen(firstId); 
                }
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const forms_columns = () => [
  { Header: "Sl No", accessor: "id" },
  { Header: "name", accessor: "name" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/form-group/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Form Group<span className="sr-only">, </span>
            </button>
          </Link>
        </>
      );
    },
  },
];

export const forms_group_columns = () => [
  { Header: "Sl No", accessor: "id" },
  { Header: "name", accessor: "name" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/form-builder/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Form Fields<span className="sr-only">, </span>
            </button>
          </Link>
        </>
      );
    },
  },
];

export const goal_master_columns = ({ onDeleteOpen }) => [
  { Header: "Name", accessor: "name" },
  {
    Header: "Department",
    accessor: (e) => (e.department ? `${e.department.name}` : "-"),
    // accessor: 'consultationTime',
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-goal/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
  ,
];

export const department_master_columns = ({ onDeleteOpen }) => [
  { Header: "Name", accessor: "name" },
  {
    Header: "Location",
    accessor: (e) =>
      e.department_location_master
        .map((ele) => `${ele.locationMaster.name}, ${ele.cost}`)
        .join(" | "),
  },
  // { Header: "Location", accessor: (e) => e.name.department_location_master ? "ab" : "ef" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-department/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const category_master_columns = ({ onDeleteOpen }) => [
  { Header: "Name", accessor: "name" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-category/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const age_group_master_columns = ({ onDeleteOpen }) => [
  { Header: "Name", accessor: "name" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-age-group/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const domain_master_columns = ({ onDeleteOpen }) => [
  { Header: "Name", accessor: "name" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-domain/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const medicine_master_columns = ({ onDeleteOpen }) => [
  { Header: "Generic Name", accessor: "generic_name" },
  {
    Header: "Strength - Formulation - Route of administration",
    accessor: (e) =>
      e.medicineDetails
        .map(
          (ele) =>
            `${ele.strength} - ${ele.formulation} - ${ele.route_of_administration}`
        )
        .join(" | "),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-medicine/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const show_availability_columns = () => [
  { Header: "Date", accessor: (d) => moment(d.date).format("DD-MM-yy") },
  {
    Header: "From Time",
    accessor: (d) => moment(d.from_time, "HH:mm").format("hh:mm A"),
  },
  {
    Header: "To Time",
    accessor: (d) => moment(d.to_time, "HH:mm").format("hh:mm A"),
  },
  {
    Header: "Week Day",
    accessor: (d) => d.weekday_name,
  },
  {
    Header: "Available",
    id: "available",
    Cell: (cell) => {
      return (
        <>
          {cell && cell.row.original.available ? (
            <GrCheckmark className="text-green-700 " />
          ) : (
            <RxCross2 className="text-red-700" />
          )}
        </>
      );
    },
  },
];
export const program_master_columns = ({ onDeleteOpen }) => [
  { Header: "Id", accessor: "id" },
  { Header: "Name", accessor: "name" },
  { Header: "Department", accessor: "department" },
  { Header: "Frequency", accessor: "frequency" },
  { Header: "Frequency type", accessor: "frequency_type" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-program/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const program_session_columns = ({ onDeleteOpen }) => [
  {
    Header: "Porgram",
    id: "program_id",
    Cell: (cell) => {
      return (
        <>
          <p className="text-gray-500 text-sm">{`${cell.row.original.program.name}`}</p>
        </>
      );
    },
  },
  {
    Header: "Department",
    accessor: "department_id",
    Cell: (cell) => {
      return (
        <>
          <p className="text-gray-500 text-sm">{`${cell.row.original.department.name}`}</p>
        </>
      );
    },
  },
  { Header: "Frequency", accessor: "frequency" },
  { Header: "Frequency Type", accessor: "frequency_type" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-program-session/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const view_patients_columns = ({
  onOpenDelete,
  updateFormDate,
  options,
  setFetchdata,
  generateQPRsheet,
}) => [
  { Header: "Name", accessor: "name" },
  {
    Header: "Type",
    accessor: "form_type",

    Cell: (cell) => {
      const tab = cell.row.original;
      return <p>{tab.form_type === "FOLLOW_UP" ? "f/up" : tab.form_type}</p>;
    },
  },
  {
    Header: "QPR",
    accessor: (e) =>
      e.qpr_sequence ? (
        e.qpr_sequence
      ) : (
        <Tippy
          theme="light"
          placement="left"
          trigger="click"
          allowHTML={true}
          interactive={true}
          content={
            <Formik
              initialValues={{
                sequence: "",
              }}
              enableReinitialize={true}
              onSubmit={async (values) => {
                const res = await axios.put(
                  `${process.env.REACT_APP_API_URL}/api/patient-form/qpr-sequence/${e.id}`,
                  {
                    sequence: values.sequence,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${
                        localStorage.getItem("newhorizon_user").token
                      }`,
                    },
                  }
                );
                if (res.data) {
                  toast.success("QPR Sequence Updated Successfully");
                  setFetchdata(true);
                }
              }}
            >
              {({ values, handleChange, handleSubmit }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                  }}
                  className="flex items-center justify-center gap-2 w-60"
                >
                  <select
                    name="sequence"
                    onChange={handleChange}
                    value={values.sequence}
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                  >
                    <option value="">Select Sequence</option>
                    {options.map((e) => (
                      <option value={e.name}>{e.name}</option>
                    ))}
                  </select>
                  <button
                    type="submit"
                    className="inline-flex items-center rounded-md border  border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Save
                  </button>
                </form>
              )}
            </Formik>
          }
        >
          <p className="text-gray-500 mr-2 text-sm cursor-pointer">N/A</p>
        </Tippy>
      ),
  },
  {
    Header: "Appointment Date",
    accessor: "appointment_date",
    Cell: (cell) => {
      //console.log("cell->" + cell);
      return (
        <React.Fragment className="text-gray-500 text-sm cursor-pointer">
          <Tippy
            theme="light"
            placement="left"
            trigger="click"
            allowHTML={true}
            interactive={true}
            content={
              <EditTooptip func={updateFormDate} id={cell.row.original.id} />
            }
          >
            <p className="text-gray-500 mr-2 text-sm cursor-pointer">
              {cell.row.original.form_date
                ? moment(cell.row.original?.form_date)
                    .utcOffset(0)
                    .format("DD-MM-yy")
                : moment(cell.row?.original?.appointment?.date)
                    .utcOffset(0)
                    .format("DD-MM-yy")}
            </p>
          </Tippy>
        </React.Fragment>
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const e = cell.row.original;
      return (
        <div className="space-x-2 ">
          {cell.row.original.finalized === true && (
            <Link
              to={`/patient-report/${cell && cell.row.original.id}`}
              className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-green-100 rounded-full"
            >
              <button
                className="text-indigo-900 "
                size="default"
                variant="outlined"
              >
                Report<span className="sr-only">, </span>
              </button>
            </Link>
          )}
          <Link
            to={`/patient-form-submission/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <button className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-red-100 rounded-full">
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
              onClick={() => {
                onOpenDelete(cell.row.original.id);
              }}
            >
              delete<span className="sr-only">, </span>
            </button>
          </button>
          {cell.row.original.qpr_sequence !== "Pre-Program" &&
            cell.row.original.qpr_sequence !== null &&
            cell.row.original.qpr_sequence !== "" && (
              <button className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-red-100 rounded-full">
                <button
                  className="text-indigo-900 "
                  size="default"
                  variant="outlined"
                  onClick={() => {
                    generateQPRsheet();
                  }}
                >
                  Generate QPR<span className="sr-only">, </span>
                </button>
              </button>
            )}
        </div>
      );
    },
  },
];
export const amcat_columns = ({ onDeleteOpen }) => [
  { Header: "Question", accessor: "question" },
  { Header: "Points", accessor: "point_for" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-mchat/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const new_medicine_master = ({ onDeleteOpen }) => [
  {
    Header: "Name",
    accessor: "medicine_name_id",
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.medicine_name_master.name}
        </p>
      );
    },
  },
  {
    Header: "Strength",
    accessor: "strength_id",
    Cell: (cell) => {
      console.log(cell);
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.medicine_strength_master.strength}
        </p>
      );
    },
  },
  {
    Header: "Formulation",
    accessor: "formulation_id",
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.medicine_formulation_master.formulation}
        </p>
      );
    },
  },
  {
    Header: "Route of Administration",
    accessor: "route_of_administration_id",
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {
            cell.row.original.medicine_route_of_administration_master
              .route_of_administration
          }
        </p>
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-medicine/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const Strength_master = ({ onDeleteOpen }) => [
  { Header: "Strength", accessor: "strength" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-strength/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const formulation_master = ({ onDeleteOpen }) => [
  { Header: "Formulation", accessor: "formulation" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-formulation/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const medicineName_master = ({ onDeleteOpen }) => [
  { Header: "Medicine Name", accessor: "name" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-medicine-name/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const routeOfAdministration_master = ({ onDeleteOpen }) => [
  { Header: "Route of Administration", accessor: "route_of_administration" },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-route-of-administration/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const enrolment_column = ({ sendPaymentLinkConfirmation }) => [
  { Header: "Patient Name", accessor: "name" },
  { Header: "Program Name", accessor: "ProgramName" },
  {
    Header: "Payment status",
    accessor: "status",

    Cell: (cell) => {
      console.log(cell);
      return (
        <>
          {cell && cell.row.original.status ? (
            <GrCheckmark className="text-green-700 " />
          ) : (
            <RxCross2 className="text-red-700" />
          )}
        </>
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <Link
            // href={`${process.env.REACT_APP_API_URL}/${
            //   cell && cell.row.original.pdf
            // }`}
            className={
              cell.row.original.pdf
                ? "inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
                : "inline-flex px-2 text-xs leading-5 text-white bg-gray-300 rounded-full cursor-default"
            }
            target="_blank"
            rel="noreferrer"
          >
            <button
              disabled={!cell.row.original.pdf}
              className={
                cell.row.original.pdf ? "text-indigo-900" : "text-gray-600"
              }
              size="default"
              variant="outlined"
            >
              Invoice<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-green-600 bg-green-100 rounded-full">
            <button
              onClick={() => {
                sendPaymentLinkConfirmation(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Send Link
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const patient_recommendation_colums = ({ onToggleFollowUp }) => [
  {
    Header: "Patient",
    accessor: "patientname",
    Cell: (cell) => {
      return (
        <>
          <p>
            {cell && cell.row.original.patient_form.patient.firstName}{" "}
            {cell && cell.row.original.patient_form.patient.lastName}
          </p>
        </>
      );
    },
  },
  {
    Header: "Program Name",
    accessor: (e) => e.programMaster.name,
  },
  {
    Header: "Last Follow Up",
    accessor: (e) =>
      e.FollowUpLogs?.length
        ? `${
            moment(e.FollowUpLogs[0].date).format("DD/MM/YYYY") +
            " " +
            moment(e.FollowUpLogs[0].time).format("HH:mm")
          }`
        : "N/A",
  },
  {
    Header: "Doctor",
    accessor: "doctorname",
    Cell: (cell) => {
      return (
        <>
          <p>
            {cell && cell.row.original.user.firstName}{" "}
            {cell && cell.row.original.user.lastName}
          </p>
        </>
      );
    },
  },
  // { Header: "Program Status", accessor: "status" },
  {
    Header: "followup",
    accessor: "name",
    Cell: (cell) => {
      return (
        <>
          <p>
            <Togglebtn
              id={cell.row.original.id}
              fun={onToggleFollowUp}
              onClick={() => onToggleFollowUp(cell.row.original.id)}
              status={cell && cell.row.original.follow_up_status}
            />
          </p>
        </>
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-follow-up/${cell && cell.row.original.id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 text-green-600 bg-green-100 rounded-full"
          >
            <button size="default" variant="outlined">
              Add Follow Up
              <span className="sr-only">, </span>
            </button>
          </Link>
        </>
      );
    },
  },
];

export const therapist_colums = ({ onDeleteOpen }) => [
  {
    Header: "Therapist Name",
    accessor: (t) => t.first_name.trim() + " " + t.last_name.trim(),
  },
  { Header: "Email", accessor: "email" },
  {
    Header: "Department",
    accessor: (e) =>
      e.Therapist_department.map((ele) => `${ele.department.name}`).join(" | "),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <div className="space-x-2">
          <Link
            to={`show-availability/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Show Availability
              <span className="sr-only">, </span>
            </button>
          </Link>
          <Link
            to={`${cell && cell.row.original.id}/schedule`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Schedule
              <span className="sr-only">, </span>
            </button>
          </Link>

          <Link
            to={`/add-therapist/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit
              <span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs  leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => onDeleteOpen(cell.row.original.id)}
              size="default"
              variant="outlined"
              className="disabled:cursor-not-allowed"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </div>
      );
    },
  },
];

export const therapist_logs_colums = ({
  onDeleteOpen,
  setModal,
  setSmsModal,
}) => [
  // { Header: "Department Name", accessor: (e)  },
  {
    Header: "Id",
    accessor: "id",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/patient-sessions/${cell.row.original.id}`}
            className="text-gray-500 text-sm"
          >
            {cell && cell.row.original.id}
          </Link>
        </>
      );
    },
  },
  {
    Header: "Patient Name",
    accessor: (e) =>
      e.patient.firstName.trim() + " " + e.patient.lastName.trim(),
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm">
          {cell && cell.row.original.patient.firstName}{" "}
          {cell && cell.row.original.patient.lastName}
        </p>
      );
    },
  },
  { Header: "Status", accessor: "status" },
  { Header: "Type", accessor: "type" },
  {
    Header: "program",
    accessor: "program",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm">
          {cell &&
            cell?.row?.original?.patient_recommendation?.programMaster?.name}
        </p>
      );
    },
  },
  { Header: "Booking type", accessor: "booking_type" },
  {
    Header: "Action",
    accessor: "booking_typed",
    Cell: (cell) => {
      return (
        <div className="flex items-center justify-between">
          <button
            onClick={() =>
              setModal((pre) => ({
                ...pre,
                state: true,
                data: cell.row.original,
              }))
            }
            className=" text-sm bg-indigo-100 px-1.5 py-2 rounded-md text-blue-900"
          >
            Add appointment
          </button>
          <button
            onClick={() =>
              setSmsModal((pre) => ({
                ...pre,
                state: true,
                index: cell.row.original.id,
                edit_id: cell.row.original.patient.phoneNo,
              }))
            }
            className=" text-sm bg-indigo-100 px-1.5 py-2 rounded-md text-blue-900"
          >
            Send Session list
          </button>
        </div>
      );
    },
  },
];

export const therapist_sessions_colums = ({ setConfirmationModal }) => [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "Therapist Name",
    accessor: (e) =>
      e.TherapistMaster.first_name + " " + e.TherapistMaster.last_name,
    Cell: (cell) => {
      return (
        <p className=" text-gray-500 text-sm ">
          {cell && cell.row.original.TherapistMaster.first_name}{" "}
          {cell && cell.row.original.TherapistMaster.last_name}
        </p>
      );
    },
  },
  {
    Header: "department",
    accessor: (e) => e.department.name,
  },
  {
    Header: "Evaluation Type",
    accessor: (e) => e.department.name,
  },
  {
    Header: "date",
    accessor: (e) => moment(e.date).format("DD-MM-YYYY"),
  },
  {
    Header: "Day",
    // accessor: (e) => moment(e.date).format("dddd"),
    accessor: (e) => moment(e.date).format("dddd"),
  },
  {
    Header: "start time",
    accessor: (e) => moment.utc(e.start_time).utcOffset(0).format("h:mm A"),
  },
  {
    Header: "End time",
    accessor: (e) => moment.utc(e.end_time).utcOffset(0).format("h:mm A"),
  },
  // {
  //   Header: "Cost",
  //   accessor: "cost",
  // },
  {
    Header: "attendance",
    accessor: (e) => e?.attendance,
    Cell: (cell) => {
      const user = JSON.parse(localStorage.getItem("newhorizon_user"));
      return (
        <p className="text-gray-500 text-sm">
          {cell.row.original.attendance ? cell.row.original.attendance : "-"}
        </p>
      );
    },
  },
  {
    Header: "Action",
    accessor: (e) => e?.attendance,
    Cell: (cell) => {
      const user = JSON.parse(localStorage.getItem("newhorizon_user"));
      return (
        <p className="text-gray-500 text-sm">
          {cell.row.original.attendance == "ABSENT" ? (
            <span
              className={classNames(
                "inline-flex px-2 text-xs leading-5 rounded-full",
                !cell.row.original.isMarkedSession
                  ? "bg-gray-100 text-gray-600  "
                  : "bg-cyan-100 text-cyan-600  "
              )}
            >
              <button
                className="text-cyan-900 disabled:cursor-not-allowed "
                size="default"
                disabled={!cell.row.original.isMarkedSession}
                variant="outlined"
                onClick={() => {
                  setConfirmationModal((prev) => ({
                    ...prev,
                    status: true,
                    id: cell.row.original.id,
                    presetnt: true,
                  }));
                }}
                // className=""
              >
                Mark Present<span className="sr-only">, </span>
              </button>
            </span>
          ) : user?.role == "CLINICAL_HEAD" ? (
            <span
              className={classNames(
                "inline-flex px-2 text-xs leading-5 rounded-full",
                cell.row.original.isMarkedSession
                  ? "bg-red-100 text-red-600  "
                  : "bg-cyan-100 text-cyan-600  "
              )}
            >
              <button
                className="text-red-900 disabled:cursor-not-allowed "
                size="default"
                disabled={!cell.row.original.isMarkedSession}
                variant="outlined"
                onClick={() => {
                  setConfirmationModal((prev) => ({
                    ...prev,
                    status: true,
                    id: cell.row.original.id,
                    presetnt: false,
                    title: "Mark Absent",
                    message: "Are you sure you want to mark absent?",
                    confirmationButtonText: "Absent",
                  }));
                }}
                // className=""
              >
                Mark Absent<span className="sr-only">, </span>
              </button>
            </span>
          ) : (
            ""
          )}
        </p>
      );
    },
  },
];
export const therapist_ev_sessions_colums = ({ setConfirmationModal }) => [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "Therapist Name",
    accessor: (e) =>
      e.TherapistMaster.first_name + " " + e.TherapistMaster.last_name,
    Cell: (cell) => {
      return (
        <p className=" text-gray-500 text-sm ">
          {cell && cell.row.original.TherapistMaster.first_name}{" "}
          {cell && cell.row.original.TherapistMaster.last_name}
        </p>
      );
    },
  },
  {
    Header: "department",
    accessor: (e) => e.DepartmentMaster.name,
  },
  {
    Header: "Evaluation Type",
    accessor: (e) => e.EvaluationMaster.name || "N/A",
  },
  {
    Header: "Type",
    accessor: (e) => e.session_type || "N/A",
  },
  {
    Header: "date",
    accessor: (e) => moment(e.date).format("DD-MM-YYYY"),
  },
  {
    Header: "Day",
    // accessor: (e) => moment(e.date).format("dddd"),
    accessor: (e) => moment(e.date).format("dddd"),
  },
  {
    Header: "start time",
    accessor: (e) => moment.utc(e.start_time).utcOffset(0).format("h:mm A"),
  },
  {
    Header: "End time",
    accessor: (e) => moment.utc(e.end_time).utcOffset(0).format("h:mm A"),
  },
  {
    Header: "Cost",
    accessor: "price",
  },
  {
    Header: "attendance",
    accessor: (e) => e?.attendance,
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm">
          {cell.row.original.attendance == "ABSENT" ? (
            <span className="inline-flex px-2 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full">
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
                onClick={async () => {
                  const cnf = window.confirm(
                    "Are you sure you want to mark present?"
                  );

                  if (cnf) {
                    const res = await app_api.get(
                      `/api/evaluation-master/mark-present/${cell.row.original.id}`
                    );
                    if (res.data) {
                      toast.success("Marked Present Successfully");
                      window.location.reload();
                    }
                  }
                }}
              >
                Mark Present<span className="sr-only">, </span>
              </button>
            </span>
          ) : (
            "Present"
          )}
        </p>
      );
    },
  },
];

export const improvment_colums = ({
  setAddRedFlagModal,
  setResolvedConfirmationModal,
}) => [
  {
    Header: "Date",
    accessor: (e) => moment(e.createdAt).format("DD-MM-YYYY"),
  },
  {
    Header: "Comments",
    accessor: "comment",
    Cell: (cell) => {
      return (
        <Link
          to={`/redflag-comments/${cell && cell.row.original.id}`}
          className="text-gray-500 text-sm cursor-pointer"
        >
          {cell && cell.row.original.comment}
        </Link>
      );
    },
  },
  {
    Header: "Reply",
    accessor: "replies",
    Cell: (cell) => {
      return (
        <Link
          to={`/redflag-comments/${cell && cell.row.original.id}`}
          className="text-gray-500 text-sm cursor-pointer"
        >
          View All Replies
        </Link>
      );
    },
  },
  {
    Header: "is Red flag",
    accessor: "is_red_flag",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm cursor-pointer">
          {cell && cell.row.original.marked_red_flag ? "Yes" : "No"}
        </p>
      );
    },
  },
  {
    Header: "Red Flag",
    accessor: "Flag",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              type="button"
              size="default"
              disabled={cell.row.original.marked_red_flag}
              variant="outlined"
              className="disabled:cursor-not-allowed"
              onClick={() =>
                setAddRedFlagModal({
                  state: true,
                  edit_id: cell.row.original.id,
                })
              }
            >
              Add Red Flag
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original.status === "RESOLVED" ? (
            <p className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
              RESOLVED
            </p>
          ) : (
            <span className="inline-flex px-2 text-xs leading-5 text-green-600 bg-green-100 rounded-full">
              <button
                type="button"
                disabled={!cell.row.original.marked_red_flag}
                onClick={() => {
                  setResolvedConfirmationModal({
                    status: true,
                    id: cell.row.original.id,
                  });
                }}
                size="default"
                variant="outlined"
                className="disabled:cursor-not-allowed"
              >
                Mark as Resolved
                <span className="sr-only">, </span>
              </button>
            </span>
          )}
        </>
      );
    },
  },
];

export const activity_Columns = ({ onDeleteOpen }) => [
  {
    Header: "Age Group",
    accessor: "age_group",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm">
          {cell && cell.row.original.AgeGroupMaster.name}
        </p>
      );
    },
  },
  {
    Header: "Domain Module title",
    accessor: "domain_module_title",
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-activity/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              Edit
              <span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs  leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              size="default"
              variant="outlined"
              className="disabled:cursor-not-allowed"
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const old_booking_Columns = () => [
  {
    Header: "department",
    accessor: "department",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm text-left">
          {cell && cell.row.original.department.name}
        </p>
      );
    },
  },
  {
    Header: "Location",
    accessor: "Location",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm text-left">
          {cell && cell.row.original.Location.name}
        </p>
      );
    },
  },
  {
    Header: "date",
    accessor: "date",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm text-left">
          {cell && moment(cell.row.original.date).format("DD-MM-YYYY")}
        </p>
      );
    },
  },
  {
    Header: "Day",
    accessor: (e) => moment(e.date).format("dddd"),
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm text-left">
          {cell && moment(cell.row.original.date).format("dddd")}
        </p>
      );
    },
  },
  {
    Header: "start time",
    accessor: "start_time",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm text-left">
          {cell &&
            moment(cell.row.original.start_time)
              .utcOffset(0)
              .format("hh : mm : A")}
        </p>
      );
    },
  },
  {
    Header: "end time",
    accessor: "end_time",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm text-left">
          {cell &&
            moment(cell.row.original.end_time)
              .utcOffset(0)
              .format("hh : mm : A")}
        </p>
      );
    },
  },
];
export const collection_data_colmn = () => [
  {
    Header: "Rate",
    accessor: "rate",
  },
  {
    Header: "count",
    accessor: "count",
  },
  {
    Header: "month",
    accessor: "month",
  },
  {
    Header: "balance",
    accessor: "balance",
  },
  {
    Header: "discount",
    accessor: "discount",
  },
  {
    Header: "payment id",
    accessor: "payment_id",
  },
  {
    Header: "Total cost",
    accessor: "total_cost",
  },
  {
    Header: "is completed",
    accessor: "is_completed",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm text-left">
          {cell && cell.row.original.is_completed ? (
            <GrCheckmark className="text-green-700 " />
          ) : (
            <RxCross2 className="text-red-700" />
          )}
        </p>
      );
    },
  },
  {
    Header: "payment source",
    accessor: "payment_source",
  },
  {
    Header: "received amount",
    accessor: "received_amount",
  },
  {
    Header: "total receivable amount",
    accessor: "total_receivable_amount",
  },
];
export const therapist_enrollment_colums = ({
  onMarkCompletedOpen,
  onMarkDropoutOpen,
  setConfirmationModal,
  setModal,
}) => [
  {
    Header: "patient Id",
    accessor: (e) => e.appointment.patient.patient_id,
    Cell: (cell) => {
      return (
        <Link
          to={`/patient-view/${cell.row.original.id}`}
          className="text-gray-500 text-sm hover:underline"
        >
          {cell && cell.row.original.appointment.patient.patient_id}
        </Link>
      );
    },
  },
  {
    Header: "Appointment Id",
    accessor: (e) => e.appointment.id,
  },
  {
    Header: "Patient Name",
    accessor: (e) =>
      capitalise(e.appointment.patient.firstName) +
      " " +
      capitalise(e.appointment.patient.lastName),
  },
  {
    Header: "program name",
    accessor: (e) => e.appointment.patient_recommendation.programMaster.name,
  },
  {
    Header: "Start Date",
    accessor: (e) => e?.first_session_date,
  },
  {
    Header: "Location",
    accessor: (e) => e?.location,
  },
  {
    Header: "Department",
    accessor: (e) =>
      e?.appointment?.department_session_details
        ?.map((ele) => `${ele?.department_name}`)
        .join(" | "),
  },
  {
    Header: "total sessions",
    accessor: (e) => e.total_session,
  },
  {
    Header: "Original Amount",
    accessor: (e) => e?.total_original_amount || "-",
  },
  {
    Header: "discount amount",
    accessor: (e) => e?.discount_amount || "-",
  },
  {
    Header: "discount percentage",
    accessor: (e) => e?.appointment?.discount_percent || "-",
  },
  {
    Header: "total amount",
    accessor: (e) => e.total_amount,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const completedPayload = {
        id: cell.row.original.id,
        completed: true,
      };
      const dropoutPayload = {
        id: cell.row.original.id,
        dropout: true,
      };
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
              onClick={() => {
                onMarkCompletedOpen(completedPayload);
              }}
            >
              Mark Completed<span className="sr-only">, </span>
            </button>
          </span>
          <span className="inline-flex ml-3 px-2 text-xs leading-5 text-indigo-600 bg-green-100 rounded-full">
            <Link
              to={`/therapist-enrolment/plan-view/${cell.row.original.id}`}
              className="text-indigo-900"
            >
              Plan View <span className="sr-only">, </span>
            </Link>
          </span>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onMarkDropoutOpen(dropoutPayload);
              }}
              size="default"
              variant="outlined"
            >
              Mark Dropout
              <span className="sr-only">, </span>
            </button>
          </span>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              type="button"
              onClick={() => {
                setModal((prev) => ({
                  ...prev,
                  state: true,
                  id: cell.row.original.id,
                }));
              }}
              size="default"
              variant="outlined"
            >
              Collection data
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const planViewColumns = () => [
  {
    Header: "Activity",
    accessor: (e) => e.activity.title,
  },
  {
    Header: "Domain",
    accessor: "goal",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm">
          {cell && cell.row.original.goal_master.name}
        </p>
      );
    },
  },
  {
    Header: "Material",
    accessor: "activity_description",
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm">
          {cell && cell.row.original.activity.description}
        </p>
      );
    },
  },
  {
    Header: "Clear",
    accessor: "clear",
    Cell: (cell) => {
      return <input type="checkbox" />;
    },
  },
];

export const holidayMaster_colums = ({ setModal, onDeleteOpen }) => [
  {
    Header: "Date",
    accessor: (e) => moment(e.date).format("DD-MM-YYYY"),
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full">
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
              onClick={() => {
                setModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "edit",
                  edit_id: cell.row.original.id,
                }));
              }}
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const therapist_appointment_log_colums = () => [
  // {
  //   Header: "Therapist",
  //   accessor: (e) =>
  //     e?.TherapistMaster?.first_name + " " + e?.TherapistMaster?.last_name,
  // },
  {
    Header: "Department",
    accessor: (e) => e?.DepartmentMaster?.name,
  },
  {
    Header: "Patient Name",
    accessor: (e) =>
      e?.EvaluationAppointments.Patient
        ? e?.EvaluationAppointments.Patient?.firstName +
          " " +
          e?.EvaluationAppointments.Patient?.lastName
        : "N/A",
  },
  {
    Header: "Location",
    accessor: (e) => e?.Location?.name,
  },
  // {
  //   Header: "Pending Amount",
  //   accessor: (e) => e?.pending_amount,
  // },
  {
    Header: "Date",
    accessor: (e) => moment(e?.date).format("DD-MM-YYYY"),
  },

  {
    Header: "start time",
    accessor: (e) => e?.start_time,
    Cell: (cell) => {
      return (
        // <p className="text-gray-500 text-sm">
        //   {/* {moment
        //     .utc(cell.row.original.start_time)
        //     .utcOffset(0)
        //     .format("h:mm A")} */}
        // </p>
        <p className="text-gray-500 text-sm">{cell.row.original.start_time}</p>
      );
    },
  },
  {
    Header: "end time",
    accessor: (e) => e?.end_time,
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm">{cell.row.original.end_time}</p>
      );
    },
  },

  // {
  //   Header: "attendance",
  //   accessor: (e) => e?.attendance,
  //   Cell: (cell) => {
  //     return (
  //       <p className="text-gray-500 text-sm">
  //         {cell.row.original.attendance == "ABSENT" ? (
  //           <span className="inline-flex px-2 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full">
  //             <button
  //               className="text-cyan-900 "
  //               size="default"
  //               variant="outlined"
  //               onClick={() => {
  //                 setConfirmationModal((prev) => ({
  //                   ...prev,
  //                   status: true,
  //                   id: cell.row.original.id,
  //                   presetnt: true,
  //                 }));
  //               }}
  //             >
  //               Mark Present<span className="sr-only">, </span>
  //             </button>
  //           </span>
  //         ) : (
  //           "Present"
  //         )}
  //       </p>
  //     );
  //   },
  // },
];
export const therapist_appointment_log_columss = ({ setConfirmationModal }) => [
  {
    Header: "Patient Name",
    accessor: (e) => (
      <Link
        to={`/patient-evaluation-sessions/${e?.id}`}
        className="hover:underline"
      >
        {e?.Patient
          ? e?.Patient?.firstName + " " + e?.Patient?.lastName
          : "N/A"}
      </Link>
    ),
  },
  {
    Header: "Age",
    accessor: (e) => e?.Patient.age,
  },
  {
    Header: "Center",
    accessor: (e) => e?.Patient.location.name || "N/A",
  },
  {
    Header: "Department",
    accessor: (e) =>
      e?.EvaluationAppointmentsSession.map((d) => d.DepartmentMaster.name).join(
        " ,"
      ) || "N/A",
  },
  // {
  //   Header: "Pending Amount",
  //   accessor: (e) => e?.pending_amount,
  // },
  // {
  //   Header: "status",
  //   accessor: (e) => e?.status,
  // },
  {
    Header: "Date",
    accessor: (e) => moment(e?.date).format("DD-MM-YYYY"),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const patient = cell.row.original.Patient;
      const cellId = cell.row.original.id;
      return (
        <div className="flex justify-center space-x-2">
          <Link
            to={`/view-patient/${patient.id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-indigo-900 "
              size="default"
              variant="outlined"
            >
              View OPD<span className="sr-only">, </span>
            </button>
          </Link>
          {cellId && (
            <Link
              to={`/patient-view/${cell.row.original.enrollment_id}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
            >
              <button
                className="text-indigo-900 "
                size="default"
                variant="outlined"
              >
                Red Flags<span className="sr-only">, </span>
              </button>
            </Link>
          )}
        </div>
      );
    },
  },
  {
    Header: "Month 1",
    accessor: "mon1",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original?.plan_builder_done === false ? (
            <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
              <Link
                className="text-cyan-900"
                size="default"
                variant="outlined"
                to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/1`}
              >
                Add Plan<span className="sr-only">, </span>
              </Link>
            </span>
          ) : (
            <div className="flex items-center justify-center gap-2">
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/therapist-enrolment/plan-view/${cell.row.original.enrollment_id}/1`}
                >
                  View Plan<span className="sr-only">, </span>
                </Link>
              </span>
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/1`}
                >
                  Edit<span className="sr-only">, </span>
                </Link>
              </span>
            </div>
          )}
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              // onClick={() => {
              //   setrConfirmationModal({
              //     status: true,
              //     id: cell.row.original.enrollment_id,
              //     body: {
              //       enrollment_id: cell.row.original.enrollment_id,
              //       month: 1,
              //     },
              //   });
              // }}
              size="default"
              variant="outlined"
            >
              Requisite
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
  {
    Header: "Month 2",
    accessor: "mon2",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original?.plan_builder_done === false ? (
            <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
              <Link
                className="text-cyan-900"
                size="default"
                variant="outlined"
                to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/2`}
              >
                Add Plan<span className="sr-only">, </span>
              </Link>
            </span>
          ) : (
            <div className="flex items-center justify-center gap-2">
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/therapist-enrolment/plan-view/${cell.row.original.enrollment_id}/2`}
                >
                  View Plan<span className="sr-only">, </span>
                </Link>
              </span>
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/2`}
                >
                  Edit<span className="sr-only">, </span>
                </Link>
              </span>
            </div>
          )}

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              onClick={() => {
                setConfirmationModal({
                  status: true,
                  id: cell.row.original.enrollment_id,
                  body: {
                    enrollment_id: cell.row.original.enrollment_id,
                    month: 2,
                  },
                });
              }}
              size="default"
              variant="outlined"
            >
              Reuse
              <span className="sr-only">, </span>
            </button>
          </span>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              // onClick={() => {
              //   setrConfirmationModal({
              //     status: true,
              //     id: cell.row.original.enrollment_id,
              //     body: {
              //       enrollment_id: cell.row.original.enrollment_id,
              //       month: 2,
              //     },
              //   });
              // }}
              size="default"
              variant="outlined"
            >
              Requisite
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
  {
    Header: "Month 3",
    accessor: "mon3",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original?.plan_builder_done === false ? (
            <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
              <Link
                className="text-cyan-900"
                size="default"
                variant="outlined"
                to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/3`}
              >
                Add Plan<span className="sr-only">, </span>
              </Link>
            </span>
          ) : (
            <div className="flex items-center justify-center gap-2">
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/therapist-enrolment/plan-view/${cell.row.original.enrollment_id}/3`}
                >
                  View Plan<span className="sr-only">, </span>
                </Link>
              </span>
              <span className="flex items-center justify-center px-2 py-1 text-xs leading-5 text-indigo-600 bg-cyan-100 rounded-full">
                <Link
                  className="text-cyan-900"
                  size="default"
                  variant="outlined"
                  to={`/patient-view/add-plan/${cell.row.original.enrollment_id}/3`}
                >
                  Edit<span className="sr-only">, </span>
                </Link>
              </span>
            </div>
          )}

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              onClick={() => {
                setConfirmationModal({
                  status: true,
                  id: cell.row.original.enrollment_id,
                  body: {
                    enrollment_id: cell.row.original.enrollment_id,
                    month: 3,
                  },
                });
              }}
              size="default"
              variant="outlined"
            >
              Reuse
              <span className="sr-only">, </span>
            </button>
          </span>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full">
            <button
              // onClick={() => {
              //   setrConfirmationModal({
              //     status: true,
              //     id: cell.row.original.enrollment_id,
              //     body: {
              //       enrollment_id: cell.row.original.enrollment_id,
              //       month: 3,
              //     },
              //   });
              // }}
              size="default"
              variant="outlined"
            >
              Requisite
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const module_colomn = ({ setModal, onDeleteOpen }) => [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full">
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
              onClick={() => {
                setModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "edit",
                  edit_id: cell.row.original.id,
                }));
              }}
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const breakTime = ({ setModal, onDeleteOpen }) => [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "From Time",
    accessor: (e) => moment(e.from_time).format("HH:mm A"),
  },
  {
    Header: "TO Time",
    accessor: (e) => moment(e.to_time).format("HH:mm A"),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full">
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
              onClick={() => {
                setModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "edit",
                  edit_id: cell.row.original.id,
                }));
              }}
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const tstColmn = () => [
  {
    Header: "Department",
    accessor: "department",
  },
  {
    Header: "Doctor",
    accessor: "doctors",
    Cell: ({ value }) => value.join(", "),
  },
  {
    Header: "Phone Number",
    accessor: "phoneNumbers",
    Cell: ({ value }) => value.join(", "),
  },
];

export const patient_view_evaluation_colums = () => [
  {
    Header: "Therapist",
    accessor: (e) =>
      e?.TherapistMaster?.first_name + " " + e?.TherapistMaster?.last_name,
  },
  {
    Header: "Department",
    accessor: (e) => e?.DepartmentMaster?.name,
  },
  {
    Header: "evaluation",
    accessor: (e) => e?.EvaluationMaster?.name,
  },
  {
    Header: "start time",
    accessor: (e) => e?.start_time,
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm">
          {moment
            .utc(cell.row.original.start_time)
            .utcOffset(0)
            .format("h:mm A")}
        </p>
      );
    },
  },
  {
    Header: "End time",
    accessor: (e) => e?.end_time,
    Cell: (cell) => {
      return (
        <p className="text-gray-500 text-sm">
          {moment.utc(cell.row.original.end_time).utcOffset(0).format("h:mm A")}
        </p>
      );
    },
  },
  {
    Header: "patient",
    accessor: (e) =>
      e?.Patient ? e?.Patient?.firstName + " " + e?.Patient?.lastName : "N/A",
  },
  {
    Header: "Location",
    accessor: (e) => e?.Location?.name,
  },
  {
    Header: "Date",
    accessor: (e) => moment(e?.date).format("DD-MM-YYYY"),
  },
];

export const specialist_performance_data = () => [
  {
    Header: "therapist name",
    accessor: (e) => e?.therapist_name,
  },
  {
    Header: "timings",
    accessor: (e) =>
      e?.timings
        .map((t) => {
          return t;
        })
        .join(", "),
  },

  {
    Header: "per session",
    accessor: (e) => e?.per_session,
  },
  {
    Header: "online sessions",
    accessor: (e) => e?.online_sessions,
  },
  {
    Header: "offline sessions",
    accessor: (e) => e?.offline_sessions,
  },
  {
    Header: "evaluation",
    accessor: (e) => e?.evaluation,
  },
  {
    Header: "enable sessions",
    accessor: (e) => e?.enable_sessions,
  },
  {
    Header: "total online sessions",
    accessor: (e) => e?.total_online_sessions,
  },
  {
    Header: "total offline and evaluation",
    accessor: (e) => e?.total_offline_and_evaluation,
  },
];
export const MIS_session_slots_columns = () => [
  {
    Header: "Location",
    accessor: (e) => e?.location_name,
  },
  {
    Header: "Total Slots",
    accessor: (e) => e?.total_slots,
  },
  {
    Header: "Booked Slots",
    accessor: (e) => e?.booked_slots,
  },
  {
    Header: "Vaccent Slots",
    accessor: (e) => e?.available_slots,
  },
  {
    Header: "Occupied Percentage",
    accessor: (e) => e?.occupied_percentage,
  },
];
export const MIS_session_columns = () => [
  {
    Header: "Location",
    accessor: (e) => e?.location_name,
  },
  {
    Header: "Total New patient",
    accessor: (e) => e?.total_new_patient,
  },
  {
    Header: "offline programs",
    accessor: (e) => e?.offline_programs,
  },
  {
    Header: "online programs",
    accessor: (e) => e?.online_programs,
  },
  {
    Header: "evaluations",
    accessor: (e) => e?.evaluations,
  },
  {
    Header: "total conversions",
    accessor: (e) => e?.total_conversions,
  },
  {
    Header: "no recommendations",
    accessor: (e) => e?.no_recommendations,
  },
  {
    Header: "conversion percentage",
    accessor: (e) => e?.conversion_percentage,
  },
  {
    Header: "non converted",
    accessor: (e) => e?.non_converted,
  },
];
export const MIS_Enrollment_columns = () => [
  {
    Header: "Location",
    accessor: (e) => e?.location_name,
  },
  {
    Header: "No of PATIENT",
    accessor: (e) => e?.total_enrollments,
  },
  {
    Header: "Re Enrollment",
    accessor: (e) => e?.re_enrollments,
  },
  {
    Header: "Drop out",
    accessor: (e) => e?.dropout,
  },
];

export const last_current_concer_improvements_column = () => [
  {
    Header: "Field Name",
    accessor: (e) => e?.form_field_name,
  },
  {
    Header: "Value",
    accessor: (e) => e?.value,
  },
];
