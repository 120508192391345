// disable eslint and prettier for this file
/* eslint-disable */
/* prettier-ignore */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import Login from "../container/pages/login/Login";
import UserGroupList from "../container/pages/userGroup/UserGroupList";
import Appointment from "../container/pages/appointment";
import ApointmentList from "../container/pages/Patients/ApointmentList";
import PatientDetails from "../container/pages/Patients/PatientDetails";
import Announcement from "../container/pages/announcement/Announcement";
import Profile from "../container/pages/profile/Profile";
import RoleList from "../container/pages/role/RoleList";
import Form_submit from "../container/pages/dynamicForm/FormSubmission";
import AddRole from "../container/pages/role/AddRole";
import AddAnnouncement from "../container/pages/announcement/AddAnnouncement";
import GlobalSettings from "../container/pages/globelSettings/GlobalSettings";
import ReferDoctor from "../container/pages/referDoctor/ReferDoctor";
import Recommendation from "../container/pages/recommendation/Recommendation";
import Prescription from "../container/pages/prescription/Prescription";
import AddPrescription from "../container/pages/prescription/AddPrescription";
import Invoices from "../container/pages/invoices/Invoice";
import AddInvoice from "../container/pages/invoices/AddInvoice";
import Users from "../container/pages/users/Users";
import NotificationList from "../container/pages/notifications/NotificationList";
import LocationList from "../container/pages/location/LocationList";
import AddLocation from "../container/pages/location/AddLocation";
import AddDoctor from "../container/pages/doctor/AddDoctor";
import DoctorList from "../container/pages/doctor/DoctorList";
import DoctorSchedule from "../container/pages/doctor/schedule/Schedule";
import AddSchedule from "../container/pages/doctor/schedule/AddSchedule";
import DynamicForm from "../container/pages/dynamicForm/formBuilder";
import MainForm from "../container/pages/dynamicForm/MainForm";
import FormList from "../container/pages/dynamicForm/FormList";
import Addpatient from "../container/pages/Patients/Addpatients";
import Viewpatient from "../container/pages/Patients/Viewpatient";
import PatientsList from "../container/pages/Patients/PatientsList";
import AddUser from "../container/pages/users/AddUser";
import AddUserGroup from "../container/pages/userGroup/AddUserGroup";
import DoctorAvailability from "../container/pages/doctor/schedule/availability/Availability";
import Payments from "../container/pages/payment";
import Opdreport from "../container/pages/appointment/Opdreport";
import Referral from "../container/pages/referal/Referral";
import Addreferral from "../container/pages/referal/Addreferral";
import GoalMaster from "../container/pages/goals/GoalMaster";
import DepartmentMaster from "../container/pages/DepartmentMaster/DepartmentMaster";
import CategoryMaster from "../container/pages/CategoryMaster/CategoryMaster";
import AgeGroupMaster from "../container/pages/AgeGroupMaster/AgeGroupMaster";
import DomainMaster from "../container/pages/DomainMaster/DomainMaster";
import AddDepartment from "../container/pages/DepartmentMaster/AddDepartment";
import AddGoal from "../container/pages/goals/AddGoal";
import AddCategory from "../container/pages/CategoryMaster/AddCategory";
import AddAgeGroup from "../container/pages/AgeGroupMaster/AddAgeGroup";
import AddDomain from "../container/pages/DomainMaster/AddDomain";
import MedicineMaster from "../container/pages/MedicineMaster/MedicineMaster";
// import AddMedicine from '../container/pages/MedicineMaster/AddMedicine';
import FormGroup from "../container/pages/dynamicForm/FormGroup";
import MedicineList from "../container/pages/medicine/MedicineList";
import MedicineForm from "../container/pages/medicinePrescription/MedicineForm";
import { useState } from "react";
import ShowAvailability from "../container/pages/doctor/schedule/availability/Showavailability";
import ProgramMaster from "../container/pages/ProgramMaster/ProgramMaster";
import AddProgram from "../container/pages/ProgramMaster/AddProgram";
import ProgramSession from "../container/pages/ProgramSession/ProgramSession";
import AddProgramSessions from "../container/pages/ProgramSession/AddProgramSessions";
import Accordian from "../container/pages/Patients/accordian";
import Amcat from "../container/pages/amcatMaster/amcat";
import Addamcat from "../container/pages/amcatMaster/addAmcat";
import Opd from "../container/pages/Patients/opd";
import Medicine from "../container/pages/newMedicineMaster/medicine";
import AddMedicine from "../container/pages/newMedicineMaster/addMedicine";
import Strength from "../container/pages/strength/strength";
import AddStrength from "../container/pages/strength/addStrength";
import Formulation from "../container/pages/formulation/Formulation";
import AddFormulation from "../container/pages/formulation/AddFormulation";
import MedicineName from "../container/pages/medicineName/medicineName";
import AddmedicineName from "../container/pages/medicineName/AddmedicineName";
import RouteOfAdministration from "../container/pages/RouteOfAdministration/RouteOfAdministration";
import AddRouteOfAdministration from "../container/pages/RouteOfAdministration/AddRouteOfAdministration";
import Enrolment from "../container/pages/Enrolment/enrolment";
import AddEnrolment from "../container/pages/Enrolment/addEnrolment";
import MchatForm from "../container/pages/amcatMaster/MchatForm";
import Forbidden from "../components/forbidden/forbidden";
import AccessControl from "./AccessControl";
import Homepage from "../components/Homepage/Homepage";
import RecommendationList from "../container/pages/patientRecommandation/recommendationList";
import PatientRecommendation from "../container/pages/Patients/PatientRecommendation";
import AddFollowUp from "../container/pages/Patients/patient recommendation action/AddFollowUp";
import Therapist from "../container/pages/therapist";
import Report from "../container/pages/therapist/Report";
import AddTherapist from "../container/pages/therapist/AddTherapist";
import TherepistShowAvailability from "../container/pages/therapist/ShowAvailability";
import TherapistScheduleList from "../container/pages/therapist/schedule";
import AddTherapistSchedule from "../container/pages/therapist/schedule/AddTherapistSchedule";
import TherapistAvailability from "../container/pages/therapist/availability";
import ReportPdf from "../container/pages/Patients/ReportPdf";
import AddTherapistApointment from "../container/pages/therapist/AddApointment";
import TherapistLogs from "../container/pages/therapist/TherapistLogs";
import Sessions from "../container/pages/therapist/Session";
import PatientView from "../container/pages/therapist/PatientView";
import LineChart from "../components/Chart/Line";
import CancelOPD from "../container/pages/appointment/CancelOPD";
import ActivityMaster from "../container/pages/AcitivityMaster";
import AddActivity from "../container/pages/AcitivityMaster/AddActivity";
import OPDReport from "../container/pages/Reports/opdReport/opdReport";
import PatientReport from "../container/pages/Reports/patientReport/patientReport";
import PatientAndSessionReport from "../container/pages/Reports/patientAndSession/patientAndSessionReport";
import ConsoliateReport from "../container/pages/Reports/consolidatedReport/consolidatedReport";
import TherapistEnrolment from "../container/pages/therapist/Enrolement";
import AddPlan from "../container/pages/therapist/AddPlan";
import PlanView from "../container/pages/therapist/PlanView";
import PaymentLogs from "../container/pages/therapist/PaymentLog";
import HolidayMaster from "../container/pages/Holiday Master";
import BreakTimeMaster from "../container/pages/BreakTime";
import NewReport from "../container/pages/Reports/patientReport/NewReport";
import ModuleMaster from "../container/pages/Module";
import PlanDashboardLog from "../container/pages/planDashboardLog";
import EvMaster from "../container/pages/evalution/EvMaster";
import EvDepartment from "../container/pages/evalution/EvDepartment";
import AppointmentLogs from "../container/pages/therapist/AppointmentLogs";
import TherapitPayment from "../container/pages/therapist/TherapistPayment";
import RedflagPayment from "../container/pages/RedFlags/RedflagPayment";
import ComentAndReplies from "../container/pages/RedFlags/ComentAndReplies";
import AllComments from "../container/pages/RedFlags/AllComents";
import MIS_Session_Slots from "../container/pages/Reports/MIS/SessionSlots";
import MisConversion from "../container/pages/Reports/MIS/MisConversion";
import SpecialistPerformance from "../container/pages/Reports/specialist";
import MisEnrollment from "../container/pages/Reports/msi/MsiEnrollment";
import QPRsheet from "../container/pages/Reports/QPRSheet";
import CountDown from "../container/pages/therapist/CountDown";
import CancledAppointmentSessionLog from "../container/pages/therapist/CancledAppointmentSessionLog";
import NewNewPatientReport from "../container/pages/Reports/patientAndSession/PatientReport";
import CollectionReport from "../container/pages/Reports/patientAndSession/CollectionReport";
import GetAllGoalLogs from "../container/pages/allGoalLogs";
import { Genograph } from "../container/pages/pedigree-chart";
import SessionsPublic from "../container/pages/therapist/publicSEssion";
import ReArange from "../container/pages/AcitivityMaster/ReArange";
import DActivityMaster from "../container/pages/AcitivityMaster/Duplicates";
import TherapistAppointmentLogs from "../container/pages/therapist/TherapistAppointmentLogs";
import UploadPedigree from "../container/pages/Patients/UploadPedigree";
import EvSessions from "../container/pages/therapist/EvaluationSession";
import CancledEvaluationSessionLog from "../container/pages/therapist/CancledEvaluationSessionLog";

const USER_ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  DOCTOR: "DOCTOR",
  CLINICAL_HEAD: "CLINICAL_HEAD",
  CLINICAL_ASSISTANT: "CLINICAL_ASSISTANT",
  RECEPTION_MANAGER: "RECEPTION_MANAGER",
  CLINICAL_ADMIN: "CLINICAL_ADMIN",
  PLAN_MAKER: "PLAN_MAKER",
  THERAPIST: "THERAPIST",
};

const RoutesConfig = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />{" "}
        <Route path="/public/session/:id" element={<SessionsPublic />} />{" "}
        <Route path="/chartdemo" element={<LineChart />} />
        <Route path="/unauthorized" element={<Forbidden />} />{" "}
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Homepage />} />{" "}
          <Route path="/notifications" element={<NotificationList />} />{" "}
          <Route
            element={<AccessControl allowedRoles={[USER_ROLES.SUPER_ADMIN]} />}
            // ROUTES NOT FOR DOCTOR
          >
            <Route path="/strength-master" element={<Strength />} />{" "}
            {/* <Route
              path="/therapist-appointment-logs"
              element={<TherapistAppointmentLogs />}
            />{" "} */}
            <Route path="/formulation-master" element={<Formulation />} />{" "}
            <Route path="/add-formulation">
              <Route index element={<AddFormulation />} />{" "}
              <Route path=":id" element={<AddFormulation />} />{" "}
            </Route>{" "}
            <Route path="/add-strength">
              <Route index element={<AddStrength />} />{" "}
              <Route path=":id" element={<AddStrength />} />{" "}
            </Route>{" "}
            <Route path="/medicine-name-master" element={<MedicineName />} />{" "}
            <Route path="/add-medicine-name">
              <Route index element={<AddmedicineName />} />{" "}
              <Route path=":id" element={<AddmedicineName />} />{" "}
            </Route>{" "}
            <Route
              path="/route-of-administration-master"
              element={<RouteOfAdministration />}
            />{" "}
            <Route path="/add-route-of-administration">
              <Route index element={<AddRouteOfAdministration />} />{" "}
              <Route path=":id" element={<AddRouteOfAdministration />} />{" "}
            </Route>
            <Route path="/users" element={<Users />} />{" "}
            <Route path="/locations" element={<LocationList />} />{" "}
            <Route path="/form-builder" element={<DynamicForm />}>
              <Route path=":id" element={<DynamicForm />} />{" "}
            </Route>{" "}
            <Route path="/form-submission" element={<Form_submit />}>
              <Route path=":id" element={<Form_submit />} />{" "}
            </Route>{" "}
            {/* //TODO: change routes access for activity master */}{" "}
            <Route
              path="/activity-master-duplicates"
              element={<DActivityMaster />}
            />
            <Route path="/add-activity">
              <Route index element={<AddActivity />} />{" "}
              <Route path=":id" element={<AddActivity />} />{" "}
            </Route>{" "}
            <Route path="/re-arrange" element={<ReArange />} />
            <Route path="/invoices" element={<Invoices />} />{" "}
            <Route path="/form" element={<MainForm />} />{" "}
            <Route path="/medicine-form" element={<MedicineForm />} />{" "}
            <Route path="/forms" element={<FormList />} />
            <Route path="/medicine-list" element={<MedicineList />} />
            {/* <Route path="/add-medicine" element={<AddMedicine />} /> */}
            <Route path="/global-settings" element={<GlobalSettings />} />{" "}
            <Route path="/refer-doctor" element={<ReferDoctor />} />{" "}
            <Route path="/department-master" element={<DepartmentMaster />} />{" "}
            <Route path="/category-master" element={<CategoryMaster />} />{" "}
            <Route path="/age-group-master" element={<AgeGroupMaster />} />{" "}
            <Route path="/domain-master" element={<DomainMaster />} />{" "}
            <Route path="/program-master" element={<ProgramMaster />} />{" "}
            <Route path="/program-session" element={<ProgramSession />} />{" "}
            <Route path="/recommendation" element={<Recommendation />} />{" "}
            <Route path="/prescription" element={<Prescription />} />{" "}
            <Route path="/announcement" element={<Announcement />} />{" "}
            <Route path="/user-group" element={<UserGroupList />} />{" "}
            <Route path="/mchat" element={<Amcat />} />{" "}
            <Route path="/profile" element={<Profile />} />
            <Route path="/medicine-master" element={<Medicine />} />{" "}
            <Route path="/opd" element={<Opd />} />{" "}
            <Route path="/doctors">
              <Route index element={<DoctorList />} />{" "}
              <Route path=":id/schedule">
                <Route index element={<DoctorSchedule />} />{" "}
                <Route path="add" element={<AddSchedule />} />{" "}
                <Route path="add/:scheduleId" element={<AddSchedule />} />{" "}
                <Route path=":scheduleId/availability">
                  <Route index element={<DoctorAvailability />} />{" "}
                  {/* <Route path="add" element={<AddAvailability />} /> */}{" "}
                  {/* <Route path=":availabilityId" element={<DoctorAvailability />} /> */}{" "}
                </Route>{" "}
              </Route>{" "}
            </Route>
            <Route path="mchat-form" element={<MchatForm />} />
            <Route path="/add-doctor">
              <Route index element={<AddDoctor />} />{" "}
              <Route path=":id" element={<AddDoctor />} />{" "}
            </Route>{" "}
            <Route path="/add-prescription">
              <Route index element={<AddPrescription />} />{" "}
              <Route path=":id" element={<AddPrescription />} />{" "}
            </Route>{" "}
            <Route path="/add-announcement">
              <Route index element={<AddAnnouncement />} />{" "}
              <Route path=":id" element={<AddAnnouncement />} />{" "}
            </Route>{" "}
            <Route path="/add-mchat">
              <Route index element={<Addamcat />} />{" "}
              <Route path=":id" element={<Addamcat />} />{" "}
            </Route>{" "}
            <Route path="/add-location">
              <Route index element={<AddLocation />} />{" "}
              <Route path=":id" element={<AddLocation />} />{" "}
            </Route>
            <Route path="/add-department">
              <Route index element={<AddDepartment />} />{" "}
              <Route path=":id" element={<AddDepartment />} />{" "}
            </Route>{" "}
            <Route path="/add-category">
              <Route index element={<AddCategory />} />{" "}
              <Route path=":id" element={<AddCategory />} />{" "}
            </Route>{" "}
            <Route path="/add-age-group">
              <Route index element={<AddAgeGroup />} />{" "}
              <Route path=":id" element={<AddAgeGroup />} />{" "}
            </Route>{" "}
            <Route path="/add-program-session">
              <Route index element={<AddProgramSessions />} />{" "}
              <Route path=":id" element={<AddProgramSessions />} />{" "}
            </Route>
            <Route path="/add-medicine">
              <Route index element={<AddMedicine />} />{" "}
              <Route path=":id" element={<AddMedicine />} />{" "}
            </Route>{" "}
            <Route path="/add-program">
              <Route index element={<AddProgram />} />{" "}
              <Route path=":id" element={<AddProgram />} />{" "}
            </Route>{" "}
            <Route path="/show-availability">
              <Route path=":id" element={<ShowAvailability />} />{" "}
            </Route>{" "}
            <Route path="/form-group">
              <Route path=":id" element={<FormGroup />} />{" "}
            </Route>{" "}
            <Route path="/add-domain">
              <Route index element={<AddDomain />} />{" "}
              <Route path=":id" element={<AddDomain />} />{" "}
            </Route>{" "}
            <Route path="/add-goal">
              <Route index element={<AddGoal />} />{" "}
              <Route path=":id" element={<AddGoal />} />{" "}
            </Route>{" "}
            <Route path="/add-user-group">
              <Route index element={<AddUserGroup />} />{" "}
              <Route path=":id" element={<AddUserGroup />} />{" "}
            </Route>{" "}
            <Route path="/add-invoice">
              <Route index element={<AddInvoice />} />{" "}
              <Route path=":id" element={<AddInvoice />} />{" "}
            </Route>{" "}
            <Route path="/add-user">
              <Route index element={<AddUser />} />{" "}
              <Route path=":id" element={<AddUser />} />{" "}
            </Route>{" "}
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_ADMIN,
                ]}
              />
            }
          >
            <Route
              path="/therapist-appointment-logs"
              element={<TherapistAppointmentLogs />}
            />
            <Route path="/activity-master" element={<ActivityMaster />} />
            <Route path="/medicine" element={<MedicineMaster />} />{" "}
            <Route path="/goal-master" element={<GoalMaster />} />{" "}
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.DOCTOR,
                  USER_ROLES.THERAPIST,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_ASSISTANT,
                  USER_ROLES.CLINICAL_ADMIN,
                  USER_ROLES.PLAN_MAKER,
                  USER_ROLES.RECEPTION_MANAGER,
                  USER_ROLES.CLINICAL_HEAD,
                ]}
              />
            }
          >
            <Route path="/patients" element={<PatientsList />} />{" "}
            <Route path="/add-enrolment">
              <Route index element={<AddEnrolment />} />{" "}
              <Route path=":id" element={<AddEnrolment />} />{" "}
            </Route>
            <Route path="/patient-sessions">
              <Route path=":id" element={<Sessions />} />{" "}
            </Route>
            <Route path="/patient-evaluation-sessions">
              <Route path=":id" element={<EvSessions />} />{" "}
            </Route>
            {/* <Route
              path="/patient-recommendation"
              element={<PatientRecommendation />}
            />{" "} */}
          </Route>{" "}
          <Route path="/patient-form-submission" element={<Accordian />} />{" "}
          <Route path="/patient-form-submission">
            <Route index element={<Accordian />} />{" "}
            <Route path=":id" element={<Accordian />} />{" "}
          </Route>{" "}
          <Route path="/therapist-master" element={<Therapist />} />{" "}
          <Route path="/therapist-payment" element={<TherapitPayment />} />{" "}
          <Route path="/appointment-logs" element={<AppointmentLogs />} />{" "}
          <Route path="/add-therapist-appointment">
            <Route index element={<AddTherapistApointment />} />{" "}
            <Route path=":id" element={<AddTherapistApointment />} />{" "}
          </Route>{" "}
          <Route path="/therapist" element={<Report />} />{" "}
          <Route path="/therapist-enrolment" element={<TherapistEnrolment />} />{" "}
          <Route path="/add-therapist">
            <Route index element={<AddTherapist />} />{" "}
            <Route path=":id" element={<AddTherapist />} />{" "}
          </Route>{" "}
          <Route path="/therapist-logs" element={<TherapistLogs />} />{" "}
          <Route path="/opd-report" element={<Opdreport />} />{" "}
          <Route path="/countdown-logs" element={<CountDown />} />{" "}
          <Route
            path="/therapist/show-availability/:id"
            element={<TherepistShowAvailability />}
          />{" "}
          <Route
            path="/therapist/show-availability"
            element={<TherepistShowAvailability />}
          />{" "}
          <Route path="therapist/:id/schedule">
            <Route index element={<TherapistScheduleList />} />{" "}
            <Route path="add" element={<AddTherapistSchedule />} />{" "}
            <Route path="add/:scheduleId" element={<AddTherapistSchedule />} />{" "}
            <Route path=":scheduleId/therapist-availability">
              <Route index element={<TherapistAvailability />} />{" "}
              <Route
                path=":availabilityId"
                element={<TherapistAvailability />}
              />{" "}
            </Route>{" "}
          </Route>{" "}
          {/* holiday */} <Route path="/holiday" element={<HolidayMaster />} />{" "}
          <Route path="/modules" element={<ModuleMaster />} />{" "}
          <Route path="/break-time" element={<BreakTimeMaster />} />{" "}
          {/* New Report */}{" "}
          <Route path="/new-report" element={<NewReport />} /> //! Get all goals
          log
          <Route path="/get-all-goals-log" element={<GetAllGoalLogs />} />{" "}
          <Route path="/patient-view/:id" element={<PatientView />} />{" "}
          {/* Plan dashboard log */}{" "}
          <Route path="/plan-dashboard" element={<PlanDashboardLog />} />{" "}
          {/* Evaluation Master */}{" "}
          <Route path="/evaluation-master" element={<EvMaster />} />{" "}
          <Route path="/evaluation-department" element={<EvDepartment />} />{" "}
          <Route
            path="/therapist-enrolment/plan-view/:id/"
            element={<PlanView />}
          />{" "}
          <Route
            path="/therapist-enrolment/plan-view/:id/:month_id"
            element={<PlanView />}
          />{" "}
          <Route path="/patient-view/add-plan/:id" element={<AddPlan />} />
          <Route
            path="/patient-view/add-plan/:id/:month"
            element={<AddPlan />}
          />
          <Route path="/therapist-payment-logs" element={<PaymentLogs />} />{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.THERAPIST]}
              />
            }
          >
            <Route
              path="/therapist-appointment-logs"
              element={<TherapistAppointmentLogs />}
            />
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.RECEPTION_MANAGER,
                  USER_ROLES.DOCTOR,
                  USER_ROLES.THERAPIST,
                  USER_ROLES.CLINICAL_ASSISTANT,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_ADMIN,
                  USER_ROLES.PLAN_MAKER,
                  USER_ROLES.CLINICAL_HEAD,
                ]}
              />
            }
          >
            <Route path="/patients" element={<PatientsList />} />{" "}
            <Route
              path="/patient-recommendation"
              element={<PatientRecommendation />}
            />
            <Route path="/program-session" element={<ProgramSession />} />{" "}
            <Route path="/recommendation" element={<Recommendation />} />{" "}
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.RECEPTION_MANAGER,
                  USER_ROLES.SUPER_ADMIN,
                ]}
              />
            }
          >
            <Route path="/payments" element={<Payments />} />{" "}
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.CLINICAL_ADMIN,
                  USER_ROLES.SUPER_ADMIN,
                ]}
              />
            }
          >
            {/* commented */}
            <Route path="/cancel-opd" element={<CancelOPD />} />{" "}
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.DOCTOR,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_ADMIN,
                  USER_ROLES.CLINICAL_HEAD,
                ]}
              />
            }
          >
            <Route path="/referrals" element={<Referral />} />{" "}
            {/* // RedFlags are here */}
            <Route
              path="/redflag-comments/:comment_id"
              element={<ComentAndReplies />}
            />
            <Route path="/redflag-all-comments" element={<AllComments />} />
            <Route path="/add-referral" element={<Addreferral />} />{" "}
            <Route path="/add-referral">
              <Route index element={<Addreferral />} />{" "}
              <Route path=":id" element={<Addreferral />} />{" "}
            </Route>
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.DOCTOR,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_ADMIN,
                ]}
              />
            }
          >
            <Route path="/redflag-payments" element={<RedflagPayment />} />
          </Route>{" "}
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.DOCTOR,
                  USER_ROLES.CLINICAL_ASSISTANT,
                  USER_ROLES.RECEPTION_MANAGER,
                  USER_ROLES.CLINICAL_ADMIN,
                  USER_ROLES.PLAN_MAKER,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_HEAD,
                ]}
              />
            }
          >
            <Route path="/upload-pedigree" element={<UploadPedigree />} />{" "}
            <Route path="/appointment" element={<Appointment />} />{" "}
            <Route path="/opd-report" element={<Opdreport />} />{" "}
            <Route path="/add-follow-up/:id" element={<AddFollowUp />} />{" "}
            <Route path="/patient-apointment" element={<ApointmentList />} />{" "}
            <Route path="/patients" element={<PatientsList />} />{" "}
            <Route path="/petient-details" element={<PatientDetails />} />
            <Route path="/add-patient">
              <Route index element={<Addpatient />} />{" "}
              <Route path=":id" element={<Addpatient />} />{" "}
            </Route>{" "}
          </Route>
          <Route
            element={
              <AccessControl
                allowedRoles={[
                  USER_ROLES.DOCTOR,
                  USER_ROLES.CLINICAL_ASSISTANT,
                  USER_ROLES.RECEPTION_MANAGER,
                  USER_ROLES.CLINICAL_ADMIN,
                  USER_ROLES.PLAN_MAKER,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.CLINICAL_HEAD,
                  USER_ROLES.THERAPIST,
                ]}
              />
            }
          >
            <Route path="/view-patient">
              <Route index element={<Viewpatient />} />{" "}
              <Route path=":id" element={<Viewpatient />} />{" "}
            </Route>{" "}
            <Route path="/patient-report">
              <Route index element={<ReportPdf />} />{" "}
              <Route path=":id" element={<ReportPdf />} />{" "}
            </Route>{" "}
          </Route>
          <Route path="/genogram/:id" element={<Genograph />} />
          {/* Reporting Routes Start from here */}{" "}
          <Route path="/patients-report" element={<PatientReport />} />{" "}
          <Route path="/opdreport" element={<OPDReport />} />{" "}
          <Route
            path="/patient-and-session-report"
            element={<PatientAndSessionReport />}
          />{" "}
          <Route
            path="/session-canceled-logs"
            element={<CancledAppointmentSessionLog />}
          />{" "}
          <Route
            path="/evaluation-canceled-logs"
            element={<CancledEvaluationSessionLog />}
          />{" "}
          <Route
            path="/patient-session-logs"
            element={<NewNewPatientReport />}
          />{" "}
          {/* <Route path="/patient-collection" element={<CollectionReport />} />{" "} */}
          {/* <Route path="/consolidate-report" element={<ConsoliateReport />} />{" "} */}
          <Route
            path="/mis-session-slot-report"
            element={<MIS_Session_Slots />}
          />
          <Route path="/mis-re-enrollment" element={<MisEnrollment />} />
          <Route path="/qpr-sheet/:patient_id" element={<QPRsheet />} />
          <Route
            path="/mis-session-slot-report-conversion"
            element={<MisConversion />}
          />
          <Route
            path="/specialist-performance"
            element={<SpecialistPerformance />}
          />
        </Route>{" "}
      </Routes>{" "}
    </Router>
  );
};

export default RoutesConfig;
